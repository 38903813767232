import React from 'react'
import PageForm from '../../components/page/PageForm'

const Page = () => {
  return (
    <div>
      <PageForm/>
    </div>
  )
}

export default Page
