import React, { useState } from "react";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

import { createPage } from "../../api/pageApi";
import CustomTableEditor from "../table/CustomTableEditor";

const PageForm = () => {
  const [formData, setFormData] = useState({
    name: "",
    thumbnail: null,
    thumbnailPreview: null,
    images: [],
    imagePreviews: [],
    description: "",
    tableData: [],
    metaTitle: "",
    metaDescription: "",
  });
  const [loading, setLoading] = useState(false);

  // Handle text input changes
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  // Handle thumbnail file input
  const handleThumbnailChange = (e) => {
    const file = e.target.files[0];
    setFormData((prev) => ({
      ...prev,
      thumbnail: file,
      thumbnailPreview: file ? URL.createObjectURL(file) : null,
    }));
  };

  // Handle multiple images input
  const handleImagesChange = (e) => {
    const files = Array.from(e.target.files);
    const newPreviews = files.map((file) => URL.createObjectURL(file));
    setFormData((prev) => ({
      ...prev,
      images: [...prev.images, ...files],
      imagePreviews: [...prev.imagePreviews, ...newPreviews],
    }));
  };

  // Remove specific image
  const handleRemoveImage = (index) => {
    setFormData((prev) => {
      const updatedImages = [...prev.images];
      const updatedPreviews = [...prev.imagePreviews];
      updatedImages.splice(index, 1);
      updatedPreviews.splice(index, 1);
      return { ...prev, images: updatedImages, imagePreviews: updatedPreviews };
    });
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    const { name, thumbnail, description, images, tableData, metaTitle, metaDescription } = formData;

    if (!name || !thumbnail || !description) {
      alert("Please fill all required fields (Page Name, Thumbnail, and Description).");
      setLoading(false);
      return;
    }

    try {
      const data = new FormData();
      data.append("name", name);
      data.append("thumbnail", thumbnail);
      images.forEach((image) => data.append("images", image));
      data.append("description", description);
      data.append("tableData", JSON.stringify(tableData));
      data.append("metaTitle", metaTitle);
      data.append("metaDescription", metaDescription);

      const response = await createPage(data);

      if (response) {
        alert("Page created successfully");
        handleReset();
      } else {
        alert("Failed to create page.");
      }
    } catch (error) {
      console.error("Error creating page:", error);
      alert("An unexpected error occurred. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  // Reset form
  const handleReset = () => {
    setFormData({
      name: "",
      thumbnail: null,
      thumbnailPreview: null,
      images: [],
      imagePreviews: [],
      description: "",
      tableData: [],
      metaTitle: "",
      metaDescription: "",
    });
  };

  return (
    <form onSubmit={handleSubmit} className="space-y-6 p-4 border rounded bg-gray-50">
      <div>
        <label className="block text-sm font-medium">Page Name *</label>
        <input
          type="text"
          name="name"
          value={formData.name}
          onChange={handleInputChange}
          required
          className="block w-full p-2 border rounded"
        />
      </div>

      <div>
        <label className="block text-sm font-medium">Thumbnail *</label>
        <div className="flex items-center space-x-4">
          <label className="cursor-pointer px-4 py-2 bg-blue-600 text-white rounded hover:bg-blue-700">
            Choose File
            <input
              type="file"
              accept="image/*"
              onChange={handleThumbnailChange}
              required
              className="hidden"
            />
          </label>
          {formData.thumbnailPreview && (
            <img
              src={formData.thumbnailPreview}
              alt="Thumbnail Preview"
              className="w-24 h-24 object-cover rounded shadow"
            />
          )}
        </div>
      </div>

      <div>
        <label className="block text-sm font-medium">Additional Images</label>
        <div className="flex items-center space-x-4">
          <label className="cursor-pointer px-4 py-2 bg-green-600 text-white rounded hover:bg-green-700">
            Choose Files
            <input
              type="file"
              accept="image/*"
              multiple
              onChange={handleImagesChange}
              className="hidden"
            />
          </label>
        </div>

        {/* Div for previewing selected images */}
        {formData.imagePreviews.length > 0 && (
          <div className="flex flex-wrap gap-4 mt-4 border rounded p-4">
            {formData.imagePreviews.map((preview, index) => (
              <div key={index} className="relative group w-24 h-24">
                <img
                  src={preview}
                  alt={`Preview ${index + 1}`}
                  className="w-full h-full object-cover rounded shadow"
                />
                <button
                  type="button"
                  onClick={() => handleRemoveImage(index)}
                  className="absolute top-0 right-0 transform translate-x-1/2 -translate-y-1/2 bg-red-500 text-white rounded-full w-6 h-6 flex items-center justify-center hover:bg-red-600"
                >
                  &times;
                </button>
              </div>
            ))}
          </div>
        )}
      </div>

      <div>
        <label className="block text-sm font-medium">Description *</label>
        <CKEditor
          editor={ClassicEditor}
          data={formData.description}
          onChange={(event, editor) => {
            const data = editor.getData();
            setFormData((prev) => ({ ...prev, description: data }));
          }}
        />
      </div>

      <div>
        <label className="block text-sm font-medium">Table</label>
        <CustomTableEditor
          initialRows={0}
          initialColumns={0}
          tableData={formData.tableData}
          onTableChange={(updatedTable) => setFormData((prev) => ({ ...prev, tableData: updatedTable }))}
        />
      </div>

      <div>
        <label className="block text-sm font-medium">Meta Title</label>
        <input
          type="text"
          name="metaTitle"
          value={formData.metaTitle}
          onChange={handleInputChange}
          className="block w-full p-2 border rounded"
        />
      </div>

      <div>
        <label className="block text-sm font-medium">Meta Description</label>
        <textarea
          name="metaDescription"
          value={formData.metaDescription}
          onChange={handleInputChange}
          className="block w-full p-2 border rounded"
        />
      </div>

      <div className="flex space-x-4">
        <button
          type="submit"
          disabled={loading}
          className={`px-4 py-2 bg-blue-600 text-white rounded ${loading ? "opacity-50" : "hover:bg-blue-700"}`}
        >
          {loading ? "Saving..." : "Save"}
        </button>
        <button
          type="button"
          onClick={handleReset}
          className="px-4 py-2 bg-gray-400 text-white rounded hover:bg-gray-500"
        >
          Reset
        </button>
      </div>
    </form>
  );
};

export default PageForm;
