import React, { Suspense, useEffect } from "react";
import { BrowserRouter, Route, Routes, Navigate  } from "react-router-dom";
import "./App.css";
import AdminDashboard from "./pages/Admin/AdminDashboard";
import { useDispatch } from "react-redux";
import { setToken } from "./features/auth/authSlice";
import ProtectedRoute from "./routes/ProtectedRoute";
import AdminMainLayout from "./pages/Admin/AdminMainLayout";
import AddBanner from "./components/admin/AddBanner";

import PublicRoute from "./routes/PublicRoute";

import SectionOne from "./components/secionOne/SectionOne";

import PageMenu from "./pages/Page/PageMenu";
import Page from "./pages/Page/Page";
import PageList from "./pages/Page/PageList";
import PageDetails from "./components/page/PageDetails";
import BlogDetails from "./components/blog/BlogDetails";
import BlogList from "./components/blog/BlogList";
import BlogEditor from "./components/blog/BlogEditor";
import PageEdit from "./components/page/PageEdit";
import ClientLogo from "./components/client/ClientLogo";
import ClientLogoAddForm from "./components/client/ClientLogoAddForm";
import GalleryForm from "./components/gallery/GalleryForm";
import Gallery from "./components/gallery/Gallery";
import Slider from "./components/slider/Slider";
import SliderForm from "./components/slider/SliderForm";
import SectionOneEditor from "./components/secionOne/SectionOneEditor";

// Lazy load the Login component
const Login = React.lazy(() => import("./components/auth/Login"));

function App() {
  const dispatch = useDispatch();

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      dispatch(setToken(token));
    }
  }, [dispatch]);

  return (
    <BrowserRouter >
      <Suspense fallback={<div>Loading...</div>}>
        <Routes>
           {/* Redirect / to /login */}
          {/* Public Route for Login */}
          <Route
            path="login"
            element={
              <PublicRoute>
                <Login />
              </PublicRoute>
            }
          />

          {/* Admin Layout */}
          <Route path="admin" element={<AdminMainLayout />}>
            {/* Protected Admin Dashboard */}
            <Route
              path="dashboard"
              element={
                <ProtectedRoute>
                  <AdminDashboard />
                </ProtectedRoute>
              }
            />
            {/* Protected AddBanner Route */}
            <Route
              path="add-banner"
              element={
                <ProtectedRoute>
                  <AddBanner />
                </ProtectedRoute>
              }
            />

            <Route
              path="section-one-form"
              element={
                <ProtectedRoute>
                 <SectionOneEditor/>
                </ProtectedRoute>
              }
            />
            <Route
              path="section-one"
              element={
                <ProtectedRoute>
                  <SectionOne />
                </ProtectedRoute>
              }
            />
            <Route
              path="client-logos"
              element={
                <ProtectedRoute>
                  <ClientLogo />
                </ProtectedRoute>
              }
            />

            <Route
              path="client-logos/add"
              element={
                <ProtectedRoute>
                  <ClientLogoAddForm isEditing={false} />
                </ProtectedRoute>
              }
            />
            <Route
              path="client-logos/edit/:id"
              element={
                <ProtectedRoute>
                  <ClientLogoAddForm isEditing={true} />
                </ProtectedRoute>
              }
            />
            <Route
              path="gallery"
              element={
                <ProtectedRoute>
                  <Gallery />
                </ProtectedRoute>
              }
            />

            <Route
              path="gallery/add"
              element={
                <ProtectedRoute>
                  <GalleryForm isEditing={false} />
                </ProtectedRoute>
              }
            />

            <Route
              path="gallery/edit/:id"
              element={
                <ProtectedRoute>
                  <GalleryForm isEditing={true} />
                </ProtectedRoute>
              }
            />

            {/* Protected Slider Management */}
            <Route
              path="sliders"
              element={
                <ProtectedRoute>
                  <Slider />
                </ProtectedRoute>
              }
            />
            {/* Protected Slider Management */}
            <Route
              path="sliders/add"
              element={
                <ProtectedRoute>
                  <SliderForm isEditing={false} />
                </ProtectedRoute>
              }
            />
            {/* Protected Slider Management */}
            <Route
              path="sliders/edit/:id"
              element={
                <ProtectedRoute>
                  <SliderForm isEditing={true} />
                </ProtectedRoute>
              }
            />
            <Route
              path="menu"
              element={
                <ProtectedRoute>
                  <PageMenu />
                </ProtectedRoute>
              }
            />
            <Route
              path="page"
              element={
                <ProtectedRoute>
                  <Page />
                </ProtectedRoute>
              }
            />
            <Route
              path="page-list"
              element={
                <ProtectedRoute>
                  <PageList />
                </ProtectedRoute>
              }
            />
           
            <Route path="page-edit/:id" element={  <ProtectedRoute>
                  <PageEdit />
                </ProtectedRoute>} />

            <Route
              path="page/:id"
              element={
                <ProtectedRoute>
                  <PageDetails />
                </ProtectedRoute>
              }
            />
            <Route
              path="blog/:slug"
              element={
                <ProtectedRoute>
                  <BlogDetails />
                </ProtectedRoute>
              }
            />

            <Route
              path="blog"
              element={
                <ProtectedRoute>
                  <BlogList />
                </ProtectedRoute>
              }
            />

            <Route
              path="create"
              element={
                <ProtectedRoute>
                  <BlogEditor />
                </ProtectedRoute>
              }
            />
          </Route>
        </Routes>
      </Suspense>
    </BrowserRouter>
  );
}

export default App;
