import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchSectionOne, updateSectionOne } from "../../features/sectionOne/sectionOneSlice";

// Use REACT_APP_BACKEND_API_URL from environment variables
const BASE_URL = process.env.REACT_APP_BACKEND_API_URL;

const SectionOneEditor = () => {
  const dispatch = useDispatch();
  const { data: section, isLoading, isSuccess } = useSelector(
    (state) => state.sectionOne
  );
  const [form, setForm] = useState({
    title: "",
    subtitle: "",
    description: "",
    img: "",
  });
  const [previewImage, setPreviewImage] = useState(null);
  const [file, setFile] = useState(null);

  // Fetch the current section data on component mount
  useEffect(() => {
    dispatch(fetchSectionOne());
  }, [dispatch]);

  // Update the form and preview image when the section data is fetched
  useEffect(() => {
    if (section) {
      setForm({
        title: section.title || "",
        subtitle: section.subtitle || "",
        description: section.description || "",
        img: "",
      }); // Reset img for the new file
      setPreviewImage(
        `${BASE_URL}/uploads/main/${section.img}?t=${new Date().getTime()}`
      ); // Add timestamp to avoid caching issues
    }
  }, [section]);

  // Handle input changes
  const handleChange = (e) => {
    const { name, value } = e.target;
    setForm((prev) => ({ ...prev, [name]: value }));
  };

  // Handle file input changes
  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    setFile(selectedFile);
    setPreviewImage(URL.createObjectURL(selectedFile)); // Temporarily show local preview
  };

  // Handle form submission
  const handleSubmit = async () => {
    const formData = new FormData();
    formData.append("title", form.title);
    formData.append("subtitle", form.subtitle);
    formData.append("description", form.description);
    if (file) {
      formData.append("img", file); // Add the image file to the request
    }

    // Dispatch the update action with formData
    await dispatch(updateSectionOne(formData));
    dispatch(fetchSectionOne()); // Re-fetch the section data to refresh the page
  };

  return (
    <div className="max-w-md mx-auto bg-white p-6 shadow-md rounded">
      <h2 className="text-2xl font-bold mb-4">Edit Section</h2>

      <input
        type="text"
        name="title"
        value={form.title}
        onChange={handleChange}
        placeholder="Title"
        className="w-full mb-4 p-2 border rounded"
      />

      <input
        type="text"
        name="subtitle"
        value={form.subtitle}
        onChange={handleChange}
        placeholder="Subtitle"
        className="w-full mb-4 p-2 border rounded"
      />

      <textarea
        name="description"
        value={form.description}
        onChange={handleChange}
        placeholder="Description"
        className="w-full mb-4 p-2 border rounded"
      ></textarea>

      <input type="file" onChange={handleFileChange} className="w-full mb-4" />

      {previewImage && (
        <img
  src={previewImage || "/default-placeholder.webp"} // Fallback to a placeholder image if previewImage is missing
  alt="Preview" // Alt text for accessibility
  className="w-32 h-32 object-cover mb-4 border rounded"
  crossOrigin="anonymous" // Enable cross-origin requests without credentials
  onError={(e) => {
    e.target.onerror = null; // Prevent infinite loop on error
    e.target.src = "/default-placeholder.webp"; // Fallback to placeholder image
  }}
/>

      )}

      <button
        onClick={handleSubmit}
        className="w-full py-2 bg-blue-500 text-white rounded hover:bg-blue-600 transition duration-200"
        disabled={isLoading}
      >
        {isLoading ? "Saving..." : "Save Changes"}
      </button>

      {isSuccess && (
        <p className="text-green-500 mt-4 text-center">
          Section updated successfully!
        </p>
      )}
    </div>
  );
};

export default SectionOneEditor;
