import React from 'react';
import SliderList from './SliderList';

const Slider = () => {
  return (
    <div>
      <SliderList />
    </div>
  );
};

export default Slider;
