// src/features/clientLogo/clientLogoService.js
import axios from "axios";
import { base_url } from "../../utils/base_url";

// Upload logo image


// Create new logo
const addLogo = async (logoData) => {
  const response = await axios.post(`${base_url}/client-logo`, logoData);
  return response.data;
};

// Fetch all logos
const fetchLogos = async () => {
  const response = await axios.get(`${base_url}/client-logo`);
  return response.data;
};

// Update client logo
const updateLogo = async (id, logoData) => {
  const response = await axios.put(`${base_url}/client-logo/${id}`, logoData);
  return response.data;
};

// Delete client logo
const deleteLogo = async (id) => {
  const response = await axios.delete(`${base_url}/client-logo/${id}`);
  return response.data;
};

export default {

  addLogo,
  fetchLogos,
  updateLogo,
  deleteLogo,
};
