import axios from "axios";
import { base_url } from "../../utils/base_url";

// Create a new slider item
const addSliderItem = async (sliderData) => {
  const response = await axios.post(`${base_url}/slider`, sliderData);
  return response.data;
};

// Fetch all slider items
const fetchSliderItems = async () => {
  const response = await axios.get(`${base_url}/slider`);
  return response.data;
};

// Update a slider item
const updateSliderItem = async (id, sliderData) => {
  const response = await axios.put(`${base_url}/slider/${id}`, sliderData);
  return response.data;
};

// Delete a slider item
const deleteSliderItem = async (id) => {
  const response = await axios.delete(`${base_url}/slider/${id}`);
  return response.data;
};

export default {
  addSliderItem,
  fetchSliderItems,
  updateSliderItem,
  deleteSliderItem,
};
