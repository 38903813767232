// src/pages/BlogEditor.js
import React, { useState } from 'react';
import ReactQuill from 'react-quill'; // Import React Quill
import 'react-quill/dist/quill.snow.css';
import { createBlog } from '../../api/blogApi';

const BlogEditor = () => {
  const [content, setContent] = useState(''); // Content is now a simple string for React Quill
  const [metaDescription, setMetaDescription] = useState('');
  const [title, setTitle] = useState('');
  const [excerpt, setExcerpt] = useState('');
  const [keywords, setKeywords] = useState('');
  const [author, setAuthor] = useState('');
  const [thumbnail, setThumbnail] = useState('');
  const [coverImage, setCoverImage] = useState('');
  const [published, setPublished] = useState(false);
  const [tags, setTags] = useState('');
  const [loading, setLoading] = useState(false);

  const handleSave = async () => {
    setLoading(true);
    const blogData = {
      title,
      content,
      metaDescription,
      excerpt,
      keywords: keywords.split(',').map((keyword) => keyword.trim()),
      author,
      thumbnail,
      coverImage,
      published,
      tags: tags.split(',').map((tag) => tag.trim()),
    };

    try {
      await createBlog(blogData);
      alert('Blog post saved!');
      // Clear fields after saving
      setTitle('');
      setContent('');
      setMetaDescription('');
      setExcerpt('');
      setKeywords('');
      setAuthor('');
      setThumbnail('');
      setCoverImage('');
      setPublished(false);
      setTags('');
    } catch (error) {
      console.error('Error saving blog:', error);
      alert('Failed to save the blog post. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="max-w-2xl mx-auto p-6 bg-white shadow-md rounded-lg">
      <h1 className="text-2xl font-bold text-gray-800 mb-6">Create Blog Post</h1>

      <input
        type="text"
        placeholder="Title"
        value={title}
        onChange={(e) => setTitle(e.target.value)}
        className="w-full mb-4 p-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
      />

      <input
        type="text"
        placeholder="Excerpt"
        value={excerpt}
        onChange={(e) => setExcerpt(e.target.value)}
        className="w-full mb-4 p-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
      />

      <input
        type="text"
        placeholder="Author"
        value={author}
        onChange={(e) => setAuthor(e.target.value)}
        className="w-full mb-4 p-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
      />

      <input
        type="text"
        placeholder="Thumbnail URL"
        value={thumbnail}
        onChange={(e) => setThumbnail(e.target.value)}
        className="w-full mb-4 p-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
      />

      <input
        type="text"
        placeholder="Cover Image URL"
        value={coverImage}
        onChange={(e) => setCoverImage(e.target.value)}
        className="w-full mb-4 p-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
      />

      <h2 className="text-lg font-semibold text-gray-700 mb-2">Main Content</h2>
      <ReactQuill
        theme="snow"
        value={content}
        onChange={setContent}
        placeholder="Write your blog content here..."
        className="mb-4"
      />

      <h2 className="text-lg font-semibold text-gray-700 mb-2">Meta Description</h2>
      <textarea
        placeholder="Write a meta description for SEO..."
        value={metaDescription}
        onChange={(e) => setMetaDescription(e.target.value)}
        className="w-full mb-4 p-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
        rows="4"
      />

      <input
        type="text"
        placeholder="Keywords (comma separated)"
        value={keywords}
        onChange={(e) => setKeywords(e.target.value)}
        className="w-full mb-4 p-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
      />

      <input
        type="text"
        placeholder="Tags (comma separated)"
        value={tags}
        onChange={(e) => setTags(e.target.value)}
        className="w-full mb-4 p-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
      />

      <label className="flex items-center mb-4">
        <input
          type="checkbox"
          checked={published}
          onChange={(e) => setPublished(e.target.checked)}
          className="mr-2"
        />
        <span className="text-gray-700">Published</span>
      </label>

      <button
        onClick={handleSave}
        disabled={loading}
        className="w-full py-3 bg-blue-500 text-white font-semibold rounded-lg shadow-md hover:bg-blue-600 disabled:bg-blue-300"
      >
        {loading ? 'Saving...' : 'Save Blog Post'}
      </button>
    </div>
  );
};

export default BlogEditor;
