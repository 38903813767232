import React from 'react';
import { NavLink } from 'react-router-dom';

const AdminSidebar = ({ isExpanded, toggleSidebar }) => {
  return (
    <div className="relative">
      {/* Sidebar */}
      <aside
        className={`fixed top-0 left-0 h-screen ${
          isExpanded ? 'w-64 p-4' : 'w-0'
        } bg-gray-800 dark:bg-gray-900 text-gray-100 shadow-lg transition-all duration-300 ease-in-out overflow-hidden`}
      >
        {/* Sidebar Content (only visible when expanded) */}
        {isExpanded && (
          <div>
            <h2 className="text-lg font-semibold mb-4">Sidebar</h2>
            <nav className="flex flex-col gap-2">
              <NavLink to="dashboard" className="hover:bg-gray-700 p-2 rounded-md">
                Dashboard
              </NavLink>
       
              <NavLink to="client-logos" className="hover:bg-gray-700 p-2 rounded-md">
                Clients Logo
              </NavLink>
              <NavLink to="gallery" className="hover:bg-gray-700 p-2 rounded-md">
                Gallery
              </NavLink>
              <NavLink to="sliders" className="hover:bg-gray-700 p-2 rounded-md">
                Sliders
              </NavLink>
              <NavLink to="section-one" className="hover:bg-gray-700 p-2 rounded-md">
                Section One
              </NavLink>
              <NavLink to="menu" className="hover:bg-gray-700 p-2 rounded-md">
                Menu
              </NavLink>
              <NavLink to="page" className="hover:bg-gray-700 p-2 rounded-md">
                Create Page
              </NavLink>
              <NavLink to="/admin/page-list" className="hover:bg-gray-700 p-2 rounded-md">
  Page List
</NavLink>


        
             
            </nav>
          </div>
        )}
      </aside>

      {/* Arrow Button */}
      <button
        onClick={toggleSidebar}
        className={`fixed top-4 ${
          isExpanded ? 'left-60' : 'left-4'
        } w-8 h-8 flex items-center justify-center bg-blue-600 dark:bg-blue-800 text-white rounded-full shadow-lg border-2 border-white dark:border-gray-700 transition-all duration-300`}
        aria-label="Toggle Sidebar"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          className="w-5 h-5 transform transition-transform duration-300"
          style={{
            transform: isExpanded ? 'rotate(180deg)' : 'rotate(0deg)',
          }}
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d="M10 6l6 6-6 6"
          />
        </svg>
      </button>
    </div>
  );
};

export default AdminSidebar;
