import React, { useState } from 'react';
import { useDraggable, useDroppable } from '@dnd-kit/core';
import { ChevronDown, Save, Trash2, X } from 'lucide-react';

const MenuItem = ({ item, onSave, onDelete }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [editName, setEditName] = useState(item.name);
  const [editLink, setEditLink] = useState(item.link || '');

  const { attributes, listeners, setNodeRef: setDragRef, transform, isDragging } = useDraggable({
    id: item._id.toString(),
  });

  const { setNodeRef: setParentDropRef, isOver: isOverParent } = useDroppable({
    id: `drop-parent-${item._id}`,
  });

  const { setNodeRef: setChildDropRef, isOver: isOverChild } = useDroppable({
    id: `drop-child-${item._id}`,
  });

  const dragStyle = {
    transform: transform ? `translate3d(${transform.x}px, ${transform.y}px, 0)` : '',
    backgroundColor: isDragging ? '#f8f8f8' : '#ffffff',
    border: '1px solid #616161',
    padding: '8px',
    borderRadius: '3px',
    marginBottom: '1px',
    cursor: 'move',
    fontWeight: 'normal',
    color: '#333',
    fontSize: '14px',
    boxShadow: '0 1px 3px rgba(0, 0, 0, 0.1)',
  };

  const parentDropZoneStyle = {
    border: isOverParent ? '1px dashed #007bff' : '1px dashed transparent',
    padding: '2px',
    marginLeft: `${item.level * 10}px`,
    transition: 'border-color 0.2s',
  };

  const childDropZoneStyle = {
    border: isOverChild ? '1px dashed #007bff' : '1px dashed transparent',
    padding: '2px',
    marginLeft: `${(item.level + 1) * 10}px`,
    transition: 'border-color 0.2s',
  };

  const handleSave = () => {
    onSave(item._id, { name: editName, link: editLink });
    setIsExpanded(false);
  };

  return (
    <div className="flex flex-col">
      <div ref={setParentDropRef} style={parentDropZoneStyle}>
        {isOverParent && <span className="text-xs text-gray-500">Drop as sibling</span>}
      </div>

      <div ref={setDragRef} style={dragStyle} className="transition-all duration-150 flex items-center justify-between">
        <span {...attributes} {...listeners} className="flex-1 cursor-move">
          {item.name}
        </span>

        <button
          onClick={(e) => {
            e.stopPropagation();
            setIsExpanded(!isExpanded);
          }}
          className="ml-2 p-1 border rounded bg-gray-100 hover:bg-gray-200 transition-all"
          style={{ width: '24px', height: '24px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}
        >
          <ChevronDown className={`h-4 w-4 transition-transform ${isExpanded ? 'rotate-180' : ''}`} />
        </button>
      </div>

      {isExpanded && (
        <div className="ml-4 mt-2 bg-gray-50 p-2 rounded border">
          <input
            type="text"
            placeholder="Edit Name"
            value={editName}
            onChange={(e) => setEditName(e.target.value)}
            className="w-full p-1 mb-2 border rounded"
          />
          <input
            type="text"
            placeholder="Edit Link"
            value={editLink}
            onChange={(e) => setEditLink(e.target.value)}
            className="w-full p-1 mb-2 border rounded"
          />

          <div className="flex justify-end space-x-2">
            <button onClick={handleSave} className="text-green-600 hover:text-green-800">
              <Save className="h-5 w-5" />
            </button>
            <button onClick={() => onDelete(item._id)} className="text-red-600 hover:text-red-800">
              <Trash2 className="h-5 w-5" />
            </button>
            <button onClick={() => setIsExpanded(false)} className="text-gray-500 hover:text-gray-700">
              <X className="h-5 w-5" />
            </button>
          </div>
        </div>
      )}

      {item.children && item.children.length > 0 && (
        <div className="ml-3 mt-1">
          {item.children.map((child) => (
            <MenuItem
              key={child._id}
              item={child}
              onSave={onSave}
              onDelete={onDelete}
            />
          ))}
        </div>
      )}

      <div ref={setChildDropRef} style={childDropZoneStyle}>
        {isOverChild && <span className="text-xs text-gray-500">Drop as child</span>}
      </div>
    </div>
  );
};

export default MenuItem;
