import React, { useState, useRef, useEffect } from 'react';
import { FaUserCircle } from 'react-icons/fa';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { logout } from '../../features/auth/authSlice'; // Adjust the path as necessary

const AdminHeader = ({ isSidebarExpanded }) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const dropdownRef = useRef(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const toggleDropdown = () => {
    setDropdownOpen((prev) => !prev);
  };

  const handleLogout = () => {
    // Dispatch the logout action
    dispatch(logout());
    // Clear the token from local storage if it’s not handled in the logout action
    localStorage.removeItem('token');
    // Redirect to login page
    navigate('/login');
  };

  // Close dropdown when clicking outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setDropdownOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <header
      className={`p-4 bg-gray-800 dark:bg-gray-900 text-gray-100 shadow-md flex justify-between items-center transition-all duration-300 ${
        isSidebarExpanded ? 'ml-64' : 'ml-16'
      }`}
    >
      {/* Empty div to push the items to the right */}
      <div></div>

      {/* Right Side Content: Visit Home link and User Icon */}
      <div className="flex items-center space-x-4">
        {/* "Visit Home" Link */}
        <Link
          to="/"
          className="text-sm font-medium text-white hover:text-gray-300 transition"
        >
          Visit Home
        </Link>

        {/* User Icon and Dropdown */}
        <div className="relative" ref={dropdownRef}>
          <button onClick={toggleDropdown} className="flex items-center text-gray-100 focus:outline-none">
            <FaUserCircle size={24} />
          </button>
          {dropdownOpen && (
            <div className="absolute right-0 mt-2 w-40 bg-white dark:bg-gray-700 rounded-md shadow-lg overflow-hidden z-10">
              <button
                onClick={handleLogout}
                className="block w-full px-4 py-2 text-left text-gray-800 dark:text-gray-200 hover:bg-gray-200 dark:hover:bg-gray-600"
              >
                Logout
              </button>
            </div>
          )}
        </div>
      </div>
    </header>
  );
};

export default AdminHeader;
