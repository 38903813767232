// app/store.js
import { configureStore } from "@reduxjs/toolkit";
import authReducer from "../features/auth/authSlice";
import sliderReducer from "../features/slider/sliderSlice";
import sectionOneReducer from '../features/sectionOne/sectionOneSlice';
import clientLogoReducer from '../features/client/clientLogoSlice';
import menuReducer from '../features/menu/menuSlice';
import galleryReducer from '../features/gallery/gallerySlice';



export const store = configureStore({
  reducer: {
    auth: authReducer,
    slider: sliderReducer,
    sectionOne: sectionOneReducer,
    clientLogo: clientLogoReducer,
    gallery: galleryReducer,
    menu: menuReducer,
  },
});


