import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { FaEdit, FaTrashAlt } from "react-icons/fa"; // Icons for edit and delete
import { AiOutlineLoading3Quarters } from "react-icons/ai"; // Loading spinner icon
import { fetchGalleryItems, deleteGalleryItem } from "../../features/gallery/gallerySlice";

const GalleryList = () => {
  const dispatch = useDispatch();
  const { galleryItems, isLoading, isError, message } = useSelector((state) => state.gallery);

  // Use the environment variable for the base API URL
  const API_BASE_URL = process.env.REACT_APP_BACKEND_API_URL ;

  useEffect(() => {
    dispatch(fetchGalleryItems());
  }, [dispatch]);

  const handleDelete = (id) => {
    if (window.confirm("Are you sure you want to delete this image?")) {
      dispatch(deleteGalleryItem(id));
    }
  };

  return (
    <div className="p-6 max-w-6xl mx-auto bg-white shadow-md rounded-lg">
      {/* Header Section */}
      <div className="flex justify-between items-center mb-6">
        <h2 className="text-2xl font-semibold text-gray-800">Gallery</h2>
        <Link
          to="/admin/gallery/add"
          className="bg-blue-600 text-white px-4 py-2 rounded hover:bg-blue-700 transition"
        >
          Add New Image
        </Link>
      </div>

      {/* Loading and Error States */}
      {isLoading && (
        <div className="flex justify-center items-center">
          <AiOutlineLoading3Quarters className="animate-spin text-3xl text-gray-500" />
        </div>
      )}
      {isError && <p className="text-red-500 text-center">{message}</p>}

      {/* Image Grid */}
      {!isLoading && galleryItems.length > 0 && (
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6">
          {galleryItems.map((image) => (
            <div
              key={image._id} // Ensure the key is unique
              className="flex flex-col items-center bg-gray-100 rounded-lg shadow hover:shadow-lg transition"
            >
              {/* Gallery Image */}
              <div
                className="relative w-full bg-gray-200"
                style={{
                  paddingTop: "56.25%", // 16:9 aspect ratio (height = 9/16 * width)
                }}
              >
       <img
  src={image.img ? `${API_BASE_URL}/uploads/main/${image.img.replace(/\\/g, "/")}` : "/default-placeholder.webp"} // Normalize path and fallback to placeholder
  alt={image.alt || "Gallery Image"} // Dynamic alt text for accessibility
  className="absolute inset-0 w-full h-full object-cover"
  crossOrigin="anonymous" // Enable cross-origin requests without credentials
  onError={(e) => {
    e.target.onerror = null; // Prevent infinite loop on error
    e.target.src = "/default-placeholder.webp"; // Fallback to a placeholder image
  }}
/>

              </div>

              {/* Actions */}
              <div className="flex justify-end w-full px-2 py-1 bg-gray-200">
                {/* Edit Button */}
                <Link
                  to={`/admin/gallery/edit/${image._id}`}
                  className="text-gray-500 hover:text-green-500 transition text-xs p-1"
                  title="Edit"
                >
                  <FaEdit />
                </Link>
                {/* Delete Button */}
                <button
                  onClick={() => handleDelete(image._id)}
                  className="text-gray-500 hover:text-red-500 transition text-xs p-1 ml-2"
                  title="Delete"
                >
                  <FaTrashAlt />
                </button>
              </div>
            </div>
          ))}
        </div>
      )}

      {/* No Images Found */}
      {!isLoading && galleryItems.length === 0 && (
        <p className="text-center text-gray-500">No gallery images found.</p>
      )}
    </div>
  );
};

export default GalleryList;
