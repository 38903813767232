import React from "react";
import Menu from "../../components/menuItem/Menu";

const PageMenu = () => {
  return <div>
<Menu/>


  </div>;
};

export default PageMenu;
