import React, { useState } from 'react';
import { Outlet } from 'react-router-dom';
import AdminSidebar from './AdminSidebar';
import AdminHeader from './AdminHeader';

const AdminLayout = () => {
  const [isSidebarExpanded, setIsSidebarExpanded] = useState(true);

  const toggleSidebar = () => {
    setIsSidebarExpanded(!isSidebarExpanded);
  };

  return (
    <div className="flex min-h-screen bg-gray-100 dark:bg-gray-900">
      {/* Sidebar */}
      <AdminSidebar isExpanded={isSidebarExpanded} toggleSidebar={toggleSidebar} />

      {/* Main Content Wrapper */}
      <div
        className={`flex-1 transition-all duration-300 ${
          isSidebarExpanded ? 'ml-64' : 'ml-0'
        }`}
      >
        {/* Header */}
        <AdminHeader isSidebarExpanded={isSidebarExpanded} />

        {/* Main Content with Light Ash Background */}
        <main className="p-4 bg-gray-50 dark:bg-gray-100 rounded-lg shadow-md min-h-screen">
          <Outlet /> {/* This is where the nested routes will render */}
        </main>
      </div>
    </div>
  );
};

export default AdminLayout;
