import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import sliderService from "./sliderService";

// Create a new slider item
export const addSliderItem = createAsyncThunk(
  "slider/addSliderItem",
  async (sliderData, thunkAPI) => {
    try {
      return await sliderService.addSliderItem(sliderData);
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response?.data || "Failed to add slider item");
    }
  }
);

// Fetch all slider items
export const fetchSliderItems = createAsyncThunk(
  "slider/fetchSliderItems",
  async (_, thunkAPI) => {
    try {
      return await sliderService.fetchSliderItems();
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response?.data || "Failed to fetch slider items");
    }
  }
);

// Update a slider item
export const updateSliderItem = createAsyncThunk(
  "slider/updateSliderItem",
  async ({ id, sliderData }, thunkAPI) => {
    try {
      return await sliderService.updateSliderItem(id, sliderData);
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response?.data || "Failed to update slider item");
    }
  }
);

// Delete a slider item
export const deleteSliderItem = createAsyncThunk(
  "slider/deleteSliderItem",
  async (id, thunkAPI) => {
    try {
      await sliderService.deleteSliderItem(id);
      return id;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response?.data || "Failed to delete slider item");
    }
  }
);

const sliderSlice = createSlice({
  name: "slider",
  initialState: {
    sliderItems: [],
    isLoading: false,
    isError: false,
    message: "",
  },
  reducers: {
    reset: (state) => {
      state.isLoading = false;
      state.isError = false;
      state.message = "";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchSliderItems.fulfilled, (state, action) => {
        state.sliderItems = action.payload;
      })
      .addCase(addSliderItem.fulfilled, (state, action) => {
        state.sliderItems.push(action.payload);
      })
      .addCase(updateSliderItem.fulfilled, (state, action) => {
        state.sliderItems = state.sliderItems.map((item) =>
          item._id === action.payload._id ? action.payload : item
        );
      })
      .addCase(deleteSliderItem.fulfilled, (state, action) => {
        state.sliderItems = state.sliderItems.filter((item) => item._id !== action.payload);
      })
      .addMatcher(
        (action) => action.type.endsWith("/pending"),
        (state) => {
          state.isLoading = true;
          state.isError = false;
          state.message = "";
        }
      )
      .addMatcher(
        (action) => action.type.endsWith("/fulfilled"),
        (state) => {
          state.isLoading = false;
        }
      )
      .addMatcher(
        (action) => action.type.endsWith("/rejected"),
        (state, action) => {
          state.isLoading = false;
          state.isError = true;
          state.message = action.payload || "An error occurred.";
        }
      );
  },
});

export const { reset } = sliderSlice.actions;
export default sliderSlice.reducer;
