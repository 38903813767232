// Redux service (in a separate file named menuService.js)
import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { base_url } from '../../utils/base_url';
import { config } from '../../utils/axiosConfig';




// Fetch Menu Items
export const fetchMenuItems = createAsyncThunk('menu/fetchMenuItems', async (_, thunkAPI) => {
  const response = await axios.get(`${base_url}/menu`);
  return response.data;
});

// Create Menu Item
export const createMenuItem = createAsyncThunk('menu/createMenuItem', async (menuItemData, thunkAPI) => {
  const response = await axios.post(`${base_url}/menu/create`, menuItemData);
  return response.data;
});

// Update Menu Item
export const updateMenuItem = createAsyncThunk('menu/updateMenuItem', async ({ id, updatedData }, thunkAPI) => {
  const response = await axios.put(`${base_url}/menu/update/${id}`, updatedData);
  return response.data;
});

// Move Menu Item
export const moveMenuItem = createAsyncThunk('menu/moveMenuItem', async (moveData, thunkAPI) => {
  const response = await axios.put(`${base_url}/menu/move`, moveData);
  return response.data;
});

// Delete Menu Item
export const deleteMenuItem = createAsyncThunk('menu/deleteMenuItem', async (id, thunkAPI) => {
  const response = await axios.delete(`${base_url}/menu/delete/${id}`);
  return response.data;
});


const menuService = {
  fetchMenuItems,
  createMenuItem,
  updateMenuItem,
  moveMenuItem,
  deleteMenuItem,
};

export default menuService;
