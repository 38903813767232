import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import sectionOneService from './sectionOneService';

// Fetch section data
export const fetchSectionOne = createAsyncThunk('sectionOne/fetch', async (_, thunkAPI) => {
  try {
    return await sectionOneService.getSectionOne();
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response?.data || 'Failed to fetch section data');
  }
});

// Update section data (including image upload)
export const updateSectionOne = createAsyncThunk('sectionOne/update', async (sectionData, thunkAPI) => {
  try {
    return await sectionOneService.updateSectionOne(sectionData);
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response?.data || 'Failed to update section data');
  }
});

// Slice
const sectionOneSlice = createSlice({
  name: 'sectionOne',
  initialState: {
    data: null,
    isLoading: false,
    isSuccess: false,
    isError: false,
    message: '',
  },
  reducers: {
    resetState: (state) => {
      state.isLoading = false;
      state.isSuccess = false;
      state.isError = false;
      state.message = '';
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchSectionOne.pending, (state) => {
        state.isLoading = true;
        state.isError = false;
        state.message = '';
      })
      .addCase(fetchSectionOne.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.data = action.payload;
      })
      .addCase(fetchSectionOne.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload || 'Error fetching section data';
      })
      .addCase(updateSectionOne.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updateSectionOne.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.data = action.payload;
      })
      .addCase(updateSectionOne.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload || 'Error updating section data';
      });
  },
});

export const { resetState } = sectionOneSlice.actions;
export default sectionOneSlice.reducer;
