import axios from "axios";
import { base_url } from "../../utils/base_url";

// Create new gallery item
const addGalleryItem = async (galleryData) => {
  const response = await axios.post(`${base_url}/gallery`, galleryData);
  return response.data;
};

// Fetch all gallery items
const fetchGalleryItems = async () => {
  const response = await axios.get(`${base_url}/gallery`);
  return response.data;
};

// Update a gallery item
const updateGalleryItem = async (id, galleryData) => {
  const response = await axios.put(`${base_url}/gallery/${id}`, galleryData);
  return response.data;
};

// Delete a gallery item
const deleteGalleryItem = async (id) => {
  const response = await axios.delete(`${base_url}/gallery/${id}`);
  return response.data;
};

export default {
  addGalleryItem,
  fetchGalleryItems,
  updateGalleryItem,
  deleteGalleryItem,
};
