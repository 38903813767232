import React, { useState, useEffect } from 'react';
import './CustomTableEditor.css';

const CustomTableEditor = ({
  initialRows = 0,
  initialColumns = 0,
  initialCellWidth = 100,
  initialCellHeight = 30,
  initialTextColor = '#000000',
  initialFontSize = 16,
  initialIsBold = false,
  initialIsItalic = false,
  initialTextAlignment = 'left',
  initialBorderThickness = 1,
  initialBorderStyle = 'solid',
  initialBorderColor = '#000000',
  initialBackgroundColor = '#ffffff',
  tableData = [], // Preloaded table data for editing
 

  // Callbacks
  onAddRow = () => {},
  onAddColumn = () => {},
  onDeleteRow = () => {},
  onDeleteColumn = () => {},
  onMergeCells = () => {},
  onUnmergeCells = () => {},
  onUpdateCellStyle = () => {},
  onUndo = () => {},
  onRedo = () => {},
  onSelectCells = () => {},
  onTableChange = () => {}, // Callback for table updates
  onSave = () => {},
  onResize = () => {},
}) => {
  const [table, setTable] = useState([]);
  const [resizeData, setResizeData] = useState(null);
  const [selectedCells, setSelectedCells] = useState([]);
  const [textColor, setTextColor] = useState(initialTextColor);
  const [fontSize, setFontSize] = useState(initialFontSize);
  const [isBold, setIsBold] = useState(initialIsBold);
  const [isItalic, setIsItalic] = useState(initialIsItalic);
  const [textAlignment, setTextAlignment] = useState(initialTextAlignment);
  const [borderThickness, setBorderThickness] = useState(initialBorderThickness);
  const [borderStyle, setBorderStyle] = useState(initialBorderStyle);
  const [borderColor, setBorderColor] = useState(initialBorderColor);
  const [backgroundColor, setBackgroundColor] = useState(initialBackgroundColor);

  const [history, setHistory] = useState([]);
  const [redoStack, setRedoStack] = useState([]);

  const MIN_CELL_SIZE = 20;

  // Initialize the table based on initialRows and initialColumns
  useEffect(() => {
    if (tableData && tableData.length > 0) {
      // Use preloaded table data if available
      setTable(tableData);
    } else if (initialRows > 0 && initialColumns > 0) {
      // Initialize table with the provided rows and columns
      const initialTable = Array.from({ length: initialRows }, () =>
        Array.from({ length: initialColumns }, () => ({
          value: '',
          width: initialCellWidth,
          height: initialCellHeight,
          styles: {
            textColor: initialTextColor,
            fontSize: initialFontSize,
            isBold: initialIsBold,
            isItalic: initialIsItalic,
            textAlignment: initialTextAlignment,
            borderThickness: initialBorderThickness,
            borderStyle: initialBorderStyle,
            borderColor: initialBorderColor,
            backgroundColor: initialBackgroundColor,
          },
          merged: false,
          hidden: false,
          rowSpan: 1,
          colSpan: 1,
        }))
      );
      setTable(initialTable);
    } else {
      // If no valid structure, initialize an empty table with one row and one column
      console.warn('Initializing fallback table with 1x1 structure');
     setTable([]);
    }
  }, [
    tableData,
    initialRows,
    initialColumns,
    initialCellWidth,
    initialCellHeight,
    initialTextColor,
    initialFontSize,
    initialIsBold,
    initialIsItalic,
    initialTextAlignment,
    initialBorderThickness,
    initialBorderStyle,
    initialBorderColor,
    initialBackgroundColor,
  ]);
  

  const updateTable = (updatedTable) => {
    setTable(updatedTable);
    onTableChange(updatedTable); // Notify parent of changes
  };

  const saveToHistory = (newTable) => {
    setHistory((prevHistory) => [...prevHistory, table]);
    setRedoStack([]);
    setTable(newTable);
    onSave(newTable);
  };

  const handleUndo = () => {
    if (history.length === 0) return;
    const previousState = history[history.length - 1];
    setHistory((prevHistory) => prevHistory.slice(0, -1));
    setRedoStack((prevRedo) => [table, ...prevRedo]);
    setTable(previousState);
    onUndo(previousState);
  };

  const handleRedo = () => {
    if (redoStack.length === 0) return;
    const nextState = redoStack[0];
    setRedoStack((prevRedo) => prevRedo.slice(1));
    setHistory((prevHistory) => [...prevHistory, table]);
    setTable(nextState);
    onRedo(nextState);
  };

  const addRow = () => {
    if (!Array.isArray(table) || table.length === 0) {
      // Initialize the table if it's empty
      const newRow = Array(initialColumns > 0 ? initialColumns : 1).fill(null).map(() => ({
        value: '',
        width: initialCellWidth,
        height: initialCellHeight,
        styles: {
          textColor,
          fontSize,
          isBold,
          isItalic,
          textAlignment,
          borderThickness,
          borderStyle,
          borderColor,
          backgroundColor,
        },
        merged: false,
        rowSpan: 1,
        colSpan: 1,
      }));
      setTable([newRow]);
      return;
    }
  
    const newRow = Array(table[0]?.length || 1).fill(null).map(() => ({
      value: '',
      width: initialCellWidth,
      height: initialCellHeight,
      styles: {
        textColor,
        fontSize,
        isBold,
        isItalic,
        textAlignment,
        borderThickness,
        borderStyle,
        borderColor,
        backgroundColor,
      },
      merged: false,
      rowSpan: 1,
      colSpan: 1,
    }));
  
    const updatedTable = [...table, newRow];
    updateTable(updatedTable);
  };
  

  const addColumn = () => {
    if (!Array.isArray(table) || table.length === 0) {
      // Initialize the table if it's empty
      const newColumn = [
        {
          value: '',
          width: initialCellWidth,
          height: initialCellHeight,
          styles: {
            textColor,
            fontSize,
            isBold,
            isItalic,
            textAlignment,
            borderThickness,
            borderStyle,
            borderColor,
            backgroundColor,
          },
          merged: false,
          rowSpan: 1,
          colSpan: 1,
        },
      ];
      setTable([newColumn]);
      return;
    }
  
    const updatedTable = table.map((row) => [
      ...row,
      {
        value: '',
        width: initialCellWidth,
        height: initialCellHeight,
        styles: {
          textColor,
          fontSize,
          isBold,
          isItalic,
          textAlignment,
          borderThickness,
          borderStyle,
          borderColor,
          backgroundColor,
        },
        merged: false,
        rowSpan: 1,
        colSpan: 1,
      },
    ]);
  
    updateTable(updatedTable);
  };
  

  const deleteRow = (rowIndex) => {
    const updatedTable = table.filter((_, index) => index !== rowIndex);
    saveToHistory(updatedTable);
    onDeleteRow(updatedTable);
    updateTable(updatedTable);
  };

  const deleteColumn = (colIndex) => {
    const updatedTable = table.map((row) => row.filter((_, index) => index !== colIndex));
    saveToHistory(updatedTable);
    onDeleteColumn(updatedTable);
    updateTable(updatedTable);
  };



  const mergeCells = () => {
    if (selectedCells.length < 2) return;
  
    const rows = selectedCells.map((cell) => parseInt(cell.split('-')[0], 10));
    const cols = selectedCells.map((cell) => parseInt(cell.split('-')[1], 10));
  
    const minRow = Math.min(...rows);
    const maxRow = Math.max(...rows);
    const minCol = Math.min(...cols);
    const maxCol = Math.max(...cols);
  
    const rowSpan = maxRow - minRow + 1;
    const colSpan = maxCol - minCol + 1;
  
    const updatedTable = [...table];
    updatedTable[minRow][minCol] = {
      ...updatedTable[minRow][minCol],
      rowSpan,
      colSpan,
      merged: true,
    };
  
    selectedCells.forEach((cell) => {
      const [rowIndex, colIndex] = cell.split('-').map(Number);
      if (rowIndex !== minRow || colIndex !== minCol) {
        updatedTable[rowIndex][colIndex] = {
          ...updatedTable[rowIndex][colIndex],
          hidden: true,
        };
      }
    });
  
    saveToHistory(updatedTable);
    setSelectedCells([`${minRow}-${minCol}`]);
    onMergeCells(updatedTable);
  };
  
  const unmergeCells = () => {
    if (selectedCells.length !== 1) return;
  
    const [rowIndex, colIndex] = selectedCells[0].split('-').map(Number);
    const cell = table[rowIndex][colIndex];
  
    if (!cell.merged) return;
  
    const updatedTable = [...table];
    for (let r = rowIndex; r < rowIndex + cell.rowSpan; r++) {
      for (let c = colIndex; c < colIndex + cell.colSpan; c++) {
        updatedTable[r][c] = {
          ...updatedTable[r][c],
          hidden: false,
          rowSpan: 1,
          colSpan: 1,
        };
      }
    }
  
    saveToHistory(updatedTable);
    onUnmergeCells(updatedTable);
  };
  
  const handleStyleChange = (styleKey, value, setter) => {
    setter(value);
    const updatedTable = [...table];
    selectedCells.forEach((cellKey) => {
      const [rowIndex, colIndex] = cellKey.split('-').map(Number);
      updatedTable[rowIndex][colIndex] = {
        ...updatedTable[rowIndex][colIndex],
        styles: {
          ...updatedTable[rowIndex][colIndex].styles,
          [styleKey]: value,
        },
      };
    });
    saveToHistory(updatedTable);
    onUpdateCellStyle(updatedTable);
  };

  const handleCellClick = (rowIndex, colIndex, event) => {
    const cellKey = `${rowIndex}-${colIndex}`;
  
    if (event.ctrlKey || event.metaKey) {
      // Add or remove cell from the selection
      setSelectedCells((prevSelected) =>
        prevSelected.includes(cellKey)
          ? prevSelected.filter((cell) => cell !== cellKey)
          : [...prevSelected, cellKey]
      );
    } else {
      // Single click selects only this cell
      setSelectedCells([cellKey]);
    }
  };
  const selectAllCells = () => {
    const allCells = [];
    table.forEach((row, rowIndex) => {
      row.forEach((_, colIndex) => {
        allCells.push(`${rowIndex}-${colIndex}`);
      });
    });
    setSelectedCells(allCells);
  };
  useEffect(() => {
    const handleKeyDown = (event) => {
      if ((event.ctrlKey || event.metaKey) && event.key === 'a') {
        event.preventDefault();
        selectAllCells();
      }
      if ((event.ctrlKey || event.metaKey) && event.key === 'z') {
        event.preventDefault();
        handleUndo();
      }
      if ((event.ctrlKey || event.metaKey) && event.key === 'y') {
        event.preventDefault();
        handleRedo();
      }
    };
  
    window.addEventListener('keydown', handleKeyDown);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [history, redoStack, table]);
      

  const handleBlur = (e, rowIndex, colIndex) => {
    const updatedTable = [...table];
    updatedTable[rowIndex][colIndex].value = e.target.innerText;
    updateTable(updatedTable);
  };

  const startResize = (e, rowIndex, colIndex, direction) => {
    e.preventDefault();
    setResizeData({
      startX: e.clientX,
      startY: e.clientY,
      rowIndex,
      colIndex,
      direction,
      initialWidth: table[rowIndex][colIndex].width,
      initialHeight: table[rowIndex][colIndex].height,
    });
  };

  // Add `useEffect` to handle resizing
  useEffect(() => {
    const handleMouseMove = (e) => {
      if (!resizeData) return;
      const { startX, rowIndex, colIndex, direction, initialWidth } = resizeData;
      const updatedTable = [...table];
      if (direction === 'horizontal') {
        const newWidth = Math.max(MIN_CELL_SIZE, initialWidth + (e.clientX - startX));
        updatedTable[rowIndex][colIndex] = {
          ...updatedTable[rowIndex][colIndex],
          width: newWidth,
        };
      }
      setTable(updatedTable);
      onResize(updatedTable);
    };

    const handleMouseUp = () => setResizeData(null);

    window.addEventListener('mousemove', handleMouseMove);
    window.addEventListener('mouseup', handleMouseUp);

    return () => {
      window.removeEventListener('mousemove', handleMouseMove);
      window.removeEventListener('mouseup', handleMouseUp);
    };
  }, [resizeData, table]);


  const handleSaveClick = () => {
    onSave(table); // Pass the current table state to the `onSave` callback
  };
  return (
    <div className="custom-table-editor-container">
      <div className="editor-wrapper">
        <h2>Custom Table Editor</h2>
  
        <div className="toolbar">
          <button type="button" onClick={addRow}>Add Row</button>
          <button type="button" onClick={addColumn}>Add Column</button>
          <button type="button" onClick={mergeCells}>Merge Cells</button>
          <button type="button" onClick={unmergeCells}>Unmerge Cells</button>
          <button type="button" onClick={handleUndo}>Undo</button>
          <button type="button" onClick={handleRedo}>Redo</button>
        </div>
  
        <div className="styling-options">
          <label>
            Text Color:
            <input
              type="color"
              value={textColor}
              onChange={(e) => handleStyleChange('textColor', e.target.value, setTextColor)}
            />
          </label>
          <label>
            Font Size:
            <input
              type="number"
              value={fontSize}
              onChange={(e) => handleStyleChange('fontSize', Number(e.target.value), setFontSize)}
              min="10"
              max="36"
            />
          </label>
          <button
            type="button"
            onClick={() => handleStyleChange('isBold', !isBold, setIsBold)}
            className={isBold ? 'active' : ''}
          >
            B
          </button>
          <button
            type="button"
            onClick={() => handleStyleChange('isItalic', !isItalic, setIsItalic)}
            className={isItalic ? 'active' : ''}
          >
            <i>I</i>
          </button>
          <label>
            Text Alignment:
            <select
              value={textAlignment}
              onChange={(e) => handleStyleChange('textAlignment', e.target.value, setTextAlignment)}
            >
              <option value="left">Left</option>
              <option value="center">Center</option>
              <option value="right">Right</option>
            </select>
          </label>
          <label>
            Border Thickness:
            <input
              type="number"
              value={borderThickness}
              onChange={(e) =>
                handleStyleChange('borderThickness', Number(e.target.value), setBorderThickness)
              }
              min="1"
              max="10"
            />
          </label>
          <label>
            Border Style:
            <select
              value={borderStyle}
              onChange={(e) => handleStyleChange('borderStyle', e.target.value, setBorderStyle)}
            >
              <option value="solid">Solid</option>
              <option value="dashed">Dashed</option>
              <option value="dotted">Dotted</option>
              <option value="double">Double</option>
            </select>
          </label>
          <label>
            Border Color:
            <input
              type="color"
              value={borderColor}
              onChange={(e) => handleStyleChange('borderColor', e.target.value, setBorderColor)}
            />
          </label>
          <label>
            Background Color:
            <input
              type="color"
              value={backgroundColor}
              onChange={(e) =>
                handleStyleChange('backgroundColor', e.target.value, setBackgroundColor)
              }
            />
          </label>
        </div>
  
        {table.length > 0 && (
          <div className="table-container">
            <table className="custom-table">
              <tbody>
                {table[0].length > 0 && (
                  <tr>
                    {table[0].map((_, colIndex) => (
                      <td key={colIndex} className="delete-column-cell">
                        <button type="button" onClick={() => deleteColumn(colIndex)} className="delete-btn">
                          ×
                        </button>
                      </td>
                    ))}
                  </tr>
                )}
                {table.map((row, rowIndex) => (
                  <tr key={rowIndex}>
                    {row.map((cell, colIndex) => {
                      if (cell.hidden) return null;
  
                      const cellKey = `${rowIndex}-${colIndex}`;
                      const isSelected = selectedCells.includes(cellKey);
  
                      return (
                        <td
                          key={colIndex}
                          className={isSelected ? 'selected-cell' : ''}
                          style={{
                            width: `${cell.width}px`,
                            minWidth: `${MIN_CELL_SIZE}px`,
                            color: cell.styles.textColor,
                            fontSize: `${cell.styles.fontSize}px`,
                            textAlign: cell.styles.textAlignment,
                            fontWeight: cell.styles.isBold ? 'bold' : 'normal',
                            fontStyle: cell.styles.isItalic ? 'italic' : 'normal',
                            backgroundColor: cell.styles.backgroundColor,
                            borderWidth: `${cell.styles.borderThickness}px`,
                            borderStyle: cell.styles.borderStyle,
                            borderColor: cell.styles.borderColor,
                          }}
                          onClick={(e) => handleCellClick(rowIndex, colIndex, e)}
                          rowSpan={cell.rowSpan}
                          colSpan={cell.colSpan}
                        >
                          <div
                            contentEditable
                            suppressContentEditableWarning
                            onBlur={(e) => handleBlur(e, rowIndex, colIndex)}
                            onPaste={(e) => {
                              e.preventDefault(); // Prevent the default paste action
                              const text = e.clipboardData.getData('text/plain'); // Get plain text from clipboard
                              document.execCommand('insertText', false, text); // Insert plain text
                            }}
                            className="cell-content"
  
                            style={{
                              justifyContent:
                                cell.styles.textAlignment === 'left'
                                  ? 'flex-start'
                                  : cell.styles.textAlignment === 'right'
                                  ? 'flex-end'
                                  : 'center',
                              textAlign: cell.styles.textAlignment, // Dynamically apply text alignment
                            }}
                          >
                            {cell.value}
                          </div>
  
                          <div
                            onMouseDown={(e) => startResize(e, rowIndex, colIndex, 'horizontal')}
                            className="resize-handle"
                          />
                        </td>
                      );
                    })}
                    <td className="delete-row-cell">
                      <button type="button" onClick={() => deleteRow(rowIndex)} className="delete-btn">
                        ×
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
      </div>
      <div></div>
    </div>
  );
  
  
  


};

export default CustomTableEditor;
