import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { addSliderItem, updateSliderItem } from "../../features/slider/sliderSlice";

// Use REACT_APP_BACKEND_API_URL from environment variables
const BASE_URL = process.env.REACT_APP_BACKEND_API_URL;

const SliderForm = ({ isEditing = false }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();

  const { sliderItems, isLoading } = useSelector((state) => state.slider);

  const [file, setFile] = useState(null);
  const [formData, setFormData] = useState({
    title: "",
    description: "",
    buttonText: "",
    buttonLink: "",
  });
  const [imagePreview, setImagePreview] = useState("");
  const [existingImage, setExistingImage] = useState(null);

  // Fetch existing slider data when editing
  useEffect(() => {
    if (isEditing && id) {
      const sliderToEdit = sliderItems.find((slider) => slider._id === id);
      if (sliderToEdit) {
        setExistingImage(sliderToEdit);
        setFormData({
          title: sliderToEdit.title,
          description: sliderToEdit.description || "",
          buttonText: sliderToEdit.buttonText || "",
          buttonLink: sliderToEdit.buttonLink || "",
        });
        setImagePreview(`${BASE_URL}/uploads/main/${sliderToEdit.img}`); // Use dynamic BASE_URL
      }
    }
  }, [isEditing, id, sliderItems]);

  // Handle file input change
  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    setFile(selectedFile);
    setImagePreview(URL.createObjectURL(selectedFile)); // Temporarily show local preview
  };

  // Handle input change
  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const sliderData = new FormData();
      sliderData.append("title", formData.title);
      sliderData.append("description", formData.description);
      sliderData.append("buttonText", formData.buttonText);
      sliderData.append("buttonLink", formData.buttonLink);
      if (file) {
        sliderData.append("img", file);
      }

      if (isEditing) {
        await dispatch(updateSliderItem({ id, sliderData })).unwrap();
        alert("Slider updated successfully!");
      } else {
        await dispatch(addSliderItem(sliderData)).unwrap();
        alert("Slider added successfully!");
      }

      navigate("/admin/sliders");
    } catch (error) {
      console.error("Error submitting form:", error);
      alert("An error occurred. Please try again.");
    }
  };

  return (
    <form onSubmit={handleSubmit} className="max-w-lg mx-auto p-6 bg-white shadow-lg rounded-lg space-y-6">
      <h2 className="text-2xl font-semibold text-gray-800 text-center">
        {isEditing ? "Edit Slider" : "Add New Slider"}
      </h2>

      {/* Input Fields */}
      <input
        type="text"
        name="title"
        placeholder="Slider Title"
        value={formData.title}
        onChange={handleChange}
        required
        className="block w-full p-3 border rounded focus:ring-2 focus:ring-blue-400"
      />
      <textarea
        name="description"
        placeholder="Slider Description"
        value={formData.description}
        onChange={handleChange}
        className="block w-full p-3 border rounded focus:ring-2 focus:ring-blue-400"
      />
      <input
        type="text"
        name="buttonText"
        placeholder="Button Text"
        value={formData.buttonText}
        onChange={handleChange}
        className="block w-full p-3 border rounded focus:ring-2 focus:ring-blue-400"
      />
      <input
        type="text"
        name="buttonLink"
        placeholder="Button Link"
        value={formData.buttonLink}
        onChange={handleChange}
        className="block w-full p-3 border rounded focus:ring-2 focus:ring-blue-400"
      />

      {/* File Input with Recommended Size Note */}
      <div className="space-y-2">
        <label className="text-sm font-medium text-gray-600 block">
          Slider Image (Recommended: 1200px x 600px)
        </label>
        <input
          type="file"
          onChange={handleFileChange}
          className="block w-full p-3 border rounded focus:ring-2 focus:ring-blue-400"
          accept="image/*"
        />
      </div>

      {/* Image Preview */}
      {imagePreview && (
        <div className="text-center">
  <img
  src={imagePreview || "/default-placeholder.webp"} // Fallback to a placeholder image if imagePreview is missing
  alt="Slider Preview" // Alt text for accessibility
  className="w-full h-48 object-contain mx-auto border rounded-lg"
  crossOrigin="anonymous" // Correct camelCase for cross-origin requests
  onError={(e) => {
    e.target.onerror = null; // Prevent infinite loop on error
    e.target.src = "/default-placeholder.webp"; // Fallback to placeholder image
  }}
/>

        </div>
      )}

      {/* Submit Button */}
      <button
        type="submit"
        disabled={isLoading}
        className="w-full py-3 text-white font-semibold rounded bg-blue-500 hover:bg-blue-600 transition focus:ring-2 focus:ring-blue-400"
      >
        {isLoading
          ? isEditing
            ? "Updating..."
            : "Uploading..."
          : isEditing
          ? "Update Slider"
          : "Add Slider"}
      </button>
    </form>
  );
};

export default SliderForm;
