// blogApi.js

import axios, { config } from "../utils/axiosConfig"; // Import axios and config

const API_URL = '/blog'; // Base endpoint for blog routes

export const createBlog = (data) => axios.post(API_URL, data, config());

export const getAllBlogs = (page = 1, limit = 10) => axios.get(`${API_URL}?page=${page}&limit=${limit}`, config());

export const getBlogBySlug = (slug) => axios.get(`${API_URL}/${slug}`, config());

export const updateBlog = (id, data) => axios.put(`${API_URL}/${id}`, data, config());

export const deleteBlog = (id) => axios.delete(`${API_URL}/${id}`, config());
