// src/components/PageMenuManager.js
import React, { useState } from 'react';
import PageSelection from '../../components/pageToMenu/PageSelection';
import AddMenuButton from '../../components/pageToMenu/AddMenuButton';


const PageMenuManager = () => {
  const [selectedPageIds, setSelectedPageIds] = useState([]);

  return (
    <div>
      <PageSelection onSelect={setSelectedPageIds} />
      <AddMenuButton selectedPageIds={selectedPageIds} />
    </div>
  );
};

export default PageMenuManager;
