// src/components/PageSelection.js
import React, { useEffect, useState } from 'react';
import { fetchPages } from '../../api/api';

const PageSelection = ({ onSelect }) => {
  const [pages, setPages] = useState([]);
  const [selectedPageIds, setSelectedPageIds] = useState([]);

  useEffect(() => {
    // Fetch available pages on component mount
    const loadPages = async () => {
      try {
        const data = await fetchPages();
        setPages(data);
      } catch (error) {
        console.error("Error fetching pages:", error);
      }
    };
    loadPages();
  }, []);

  const handleCheckboxChange = (pageId) => {
    setSelectedPageIds((prevSelected) =>
      prevSelected.includes(pageId)
        ? prevSelected.filter((id) => id !== pageId)
        : [...prevSelected, pageId]
    );
  };

  useEffect(() => {
    // Pass selected page IDs to parent component
    onSelect(selectedPageIds);
  }, [selectedPageIds, onSelect]);

  return (
    <div className="p-6 max-w-md mx-auto bg-white rounded-lg shadow-md">
      <h2 className="text-2xl font-semibold mb-4 text-gray-800">Select Pages to Add to Menu</h2>
      <ul className="space-y-2">
        {pages.map((page) => (
          <li key={page._id} className="flex items-center space-x-3">
            <input
              type="checkbox"
              id={`page-${page._id}`}
              value={page._id}
              onChange={() => handleCheckboxChange(page._id)}
              className="h-4 w-4 text-blue-600 focus:ring-blue-500 border-gray-300 rounded"
            />
            <label htmlFor={`page-${page._id}`} className="text-gray-700">{page.name}</label>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default PageSelection;
