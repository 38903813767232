import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { addGalleryItem, updateGalleryItem } from "../../features/gallery/gallerySlice";

const GalleryForm = ({ isEditing = false }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();

  const { galleryItems, isLoading } = useSelector((state) => state.gallery);

  const [file, setFile] = useState(null);
  const [imagePreview, setImagePreview] = useState("");
  const [existingImage, setExistingImage] = useState(null);

  // Use the environment variable for the base API URL
  const API_BASE_URL = process.env.REACT_APP_BACKEND_API_URL ;

  // Fetch existing image data when editing
  useEffect(() => {
    if (isEditing && id) {
      const imageToEdit = galleryItems.find((image) => image._id === id);
      if (imageToEdit) {
        setExistingImage(imageToEdit);
        setImagePreview(`${API_BASE_URL}/uploads/main/${imageToEdit.img}`);
      }
    }
  }, [isEditing, id, galleryItems, API_BASE_URL]);

  // Handle file input change
  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    setFile(selectedFile);
    setImagePreview(URL.createObjectURL(selectedFile));
  };

  // Handle form submission for both add and edit
  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const formData = new FormData();
      if (file) {
        formData.append("img", file); // Attach image file
      }

      if (isEditing) {
        // Update existing gallery image
        await dispatch(updateGalleryItem({ id, galleryData: formData })).unwrap();
        alert("Image updated successfully!");
      } else {
        // Add new gallery image
        await dispatch(addGalleryItem(formData)).unwrap();
        alert("Image added successfully!");
      }

      navigate("/admin/gallery"); // Redirect to gallery list
    } catch (error) {
      console.error("Error submitting form:", error);
      alert("An error occurred. Please try again.");
    }
  };

  return (
    <form onSubmit={handleSubmit} className="max-w-md mx-auto p-6 bg-white shadow-md rounded-lg space-y-6">
      <h2 className="text-2xl font-semibold text-gray-700 text-center">
        {isEditing ? "Edit Gallery Image" : "Add New Gallery Image"}
      </h2>

      {/* File Input with Info */}
      <div>
        <label className="block text-gray-700 font-medium mb-1">
          Upload Image <span className="text-sm text-gray-500">(Recommended: 533x300 pixels or 16:9 aspect ratio)</span>
        </label>
        <input
          type="file"
          onChange={handleFileChange}
          className="block w-full p-2 border rounded"
        />
      </div>

      {/* Image Preview */}
      {imagePreview && (
        <div className="text-center">
     <img
  src={imagePreview || "/default-placeholder.webp"} // Fallback to a placeholder image if imagePreview is missing
  alt="Gallery Preview" // Alt text for accessibility
  className="w-32 h-32 object-contain mx-auto border rounded"
  crossOrigin="anonymous" // Enable cross-origin requests without credentials
  onError={(e) => {
    e.target.onerror = null; // Prevent infinite loop on error
    e.target.src = "/default-placeholder.webp"; // Fallback to placeholder image
  }}
/>

        </div>
      )}

      {/* Submit Button */}
      <button
        type="submit"
        disabled={isLoading}
        className="w-full py-2 text-white font-semibold rounded bg-blue-500 hover:bg-blue-600 transition"
      >
        {isLoading
          ? isEditing
            ? "Updating..."
            : "Uploading..."
          : isEditing
          ? "Update Image"
          : "Add Image"}
      </button>
    </form>
  );
};

export default GalleryForm;
