import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { fetchPageById, deletePageById } from "../../api/pageApi"; // Use the correct API methods
import TableDisplay from "./TableDisplay"; // Import the table display component
import "./css/PageDetails.css"; // Import custom CSS for styling

// Use REACT_APP_BACKEND_API_URL from environment variables
const BASE_URL = process.env.REACT_APP_BACKEND_API_URL;

const PageDetails = () => {
  const { id } = useParams(); // Use the ID from route params
  const [page, setPage] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  // Fetch page details by ID
  useEffect(() => {
    const fetchPageDetails = async () => {
      try {
        setLoading(true);
        const data = await fetchPageById(id); // Fetch page by ID
        setPage(data);
      } catch (err) {
        console.error("Error fetching page details:", err);
        setError("Failed to load page details.");
      } finally {
        setLoading(false);
      }
    };

    fetchPageDetails();
  }, [id]);

  if (loading)
    return <div className="text-center text-gray-600 loading">Loading...</div>;
  if (error)
    return <div className="text-center text-red-600 error">{error}</div>;
  if (!page)
    return <div className="text-center text-gray-600 not-found">Page not found</div>;

  // Handle delete action
  const handleDelete = async (pageId) => {
    if (window.confirm("Are you sure you want to delete this page?")) {
      try {
        await deletePageById(pageId); // Delete the page by ID
        alert("Page deleted successfully");
        navigate("/pages"); // Redirect to the list after deletion
      } catch (err) {
        console.error("Error deleting page:", err);
        alert("Failed to delete the page");
      }
    }
  };

  // Handle edit action
  const handleEdit = (pageId) => {
    navigate(`/admin/page-edit/${pageId}`); // Navigate to the edit page by ID
  };

  return (
    <div className="page-details-container">
      <div className="page-header">
        <h1>{page.name}</h1>
      </div>

      {/* Thumbnail */}
      {page.thumbnail && (
        <div className="thumbnail">
          <h2>Thumbnail</h2>
          <img
  src={page.thumbnail ? `${BASE_URL}/uploads/${page.thumbnail.replace(/\\/g, "/")}` : "/default-placeholder.webp"} // Normalize path and fallback to placeholder
  alt="Page Thumbnail" // Alt text for accessibility
  crossOrigin="anonymous" // Enable cross-origin requests without credentials
  onError={(e) => {
    e.target.onerror = null; // Prevent infinite loop on error
    e.target.src = "/default-placeholder.webp"; // Fallback to placeholder image
  }}
/>

        </div>
      )}

      {/* Additional Images */}
      {page.images && page.images.length > 0 && (
        <div className="additional-images">
          <h2>Additional Images</h2>
          <div className="image-grid">
            {page.images.map((image, index) => (
       <img
       key={index}
       src={image ? `${BASE_URL}/uploads/${image.replace(/\\/g, "/")}` : "/default-placeholder.webp"} // Normalize path and fallback to placeholder
       alt={`Image ${index + 1}`} // Dynamic alt text for accessibility
       crossOrigin="anonymous" // Enable cross-origin requests without credentials
       onError={(e) => {
         e.target.onerror = null; // Prevent infinite loop on error
         e.target.src = "/default-placeholder.webp"; // Fallback to placeholder image
       }}
       className="w-full h-auto rounded shadow" // Optional styling for consistent rendering
     />
     
            ))}
          </div>
        </div>
      )}

      {/* Description */}
      {page.description && (
        <div className="description">
          <h2>Description</h2>
          <div
            className="description-content ck-editor-styled-tables"
            dangerouslySetInnerHTML={{
              __html: page.description.replace(
                /<img src="(.*?)"/g,
                `<img src="${BASE_URL}/uploads/$1"`
              ),
            }}
          />
        </div>
      )}

      {/* Table Data */}
      {page.tableData && page.tableData.length > 0 && (
        <div className="table-section">
          <h2>Table</h2>
          <TableDisplay tableData={page.tableData} />
        </div>
      )}

      {/* Meta Information */}
      <div className="meta-info">
        <h2>Meta Information</h2>
        <div className="meta-content">
          <p>
            <strong>Meta Title:</strong> {page.metaTitle || "N/A"}
          </p>
          <p>
            <strong>Meta Description:</strong> {page.metaDescription || "N/A"}
          </p>
        </div>
      </div>

      {/* Action Buttons */}
      <div className="action-buttons flex space-x-2">
        <button
          onClick={() => handleEdit(page._id)}
          className="px-3 py-1 bg-green-500 text-white rounded hover:bg-green-600"
        >
          Edit
        </button>
        <button
          onClick={() => handleDelete(page._id)}
          className="px-3 py-1 bg-red-500 text-white rounded hover:bg-red-600"
        >
          Delete
        </button>
      </div>
    </div>
  );
};

export default PageDetails;
