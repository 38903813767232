// src/pages/Admin/PageList.js
import React, { useEffect, useState } from "react";
import { fetchAllPages, deletePageById } from "../../api/pageApi";
import { Link, useNavigate } from "react-router-dom";

const PageList = () => {
  const [pages, setPages] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  const getPages = async () => {
    try {
      setLoading(true);
      const data = await fetchAllPages();
      setPages(data);
    } catch (err) {
      console.error("Error fetching pages:", err);
      setError("Failed to load pages");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getPages();
  }, []);

  const handleDelete = async (id) => {
    if (window.confirm("Are you sure you want to delete this page?")) {
      try {
        await deletePageById(id);
        alert("Page deleted successfully");
        getPages();
      } catch (error) {
        console.error("Error deleting page:", error);
        alert("Failed to delete the page");
      }
    }
  };

  const handleEdit = (id) => {
    navigate(`/admin/page-edit/${id}`);
  };

  if (loading) return <div>Loading...</div>;
  if (error) return <div className="text-red-500">{error}</div>;

  return (
    <div className="page-list p-4">
      <h1 className="text-xl font-bold mb-4">Page List</h1>
      <ul className="space-y-4">
        {pages.map((page) => (
          <li key={page._id} className="flex justify-between items-center p-4 border rounded">
            <div>
              <Link to={`/admin/page/${page._id}`} className="text-blue-500 hover:underline">
                {page.name}
              </Link>
              <p className="text-sm text-gray-500">{page.slug}</p>
            </div>
            <div className="flex space-x-2">
              <button
                onClick={() => handleEdit(page._id)}
                className="px-3 py-1 bg-green-500 text-white rounded hover:bg-green-600"
              >
                Edit
              </button>
              <button
                onClick={() => handleDelete(page._id)}
                className="px-3 py-1 bg-red-500 text-white rounded hover:bg-red-600"
              >
                Delete
              </button>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default PageList;
