// src/api/api.js
import axios, { config } from "../utils/axiosConfig"; // Importing the Axios instance as axiosInstance

// Function to fetch pages
export const fetchPages = async () => {
  const response = await axios.get("/page/all", config()); // Use axiosInstance for requests
  return response.data; // Return the response data
};

// Function to add pages to the menu
export const addPagesToMenu = async (pageIds) => {
  const response = await axios.post(
    "/menu/add-page-menu-items",
    { pageIds },
    config()
  ); // Use axiosInstance for requests
  return response.data; // Return the response data
};

//for create page

export const createPage = async (data) => {
  try {
    console.log("Data being sent in POST request:", data); // Debugging statement
    const response = await axios.post(`/page`, data);
    console.log("Response from server:", response.data); // Debugging statement
    return response.data;
  } catch (error) {
    console.error(
      "Error creating page:",
      error.response?.data || error.message
    ); // Enhanced error logging
  }
};

export const uploadSingleImage = async (file) => {
  const formData = new FormData();
  formData.append("file", file);
  try {
    const response = await axios.post(`/upload/single`, formData);
    return response.data;
  } catch (error) {
    console.error("Error uploading thumbnail:", error);
  }
};

export const uploadMultipleImages = async (files) => {
  const formData = new FormData();
  files.forEach((file) => formData.append("files", file));
  try {
    const response = await axios.post(`/upload/multiple`, formData);
    return response.data;
  } catch (error) {
    console.error("Error uploading images:", error);
  }
};

// Function to fetch a single page by ID
export const fetchPageById = async (pageId) => {
  try {
    const response = await axios.get(`/api/page/${pageId}`);
    return response.data;
  } catch (error) {
    console.error("Error fetching page data:", error);
    throw error;
  }
};

// Other API functions related to "Page" can go here if needed.
