import React from 'react'

const AddBanner = () => {
  return (
    <div>
      add banners page
    </div>
  )
}

export default AddBanner
