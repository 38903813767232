import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import galleryService from "./galleryService";

// Create a new gallery item
export const addGalleryItem = createAsyncThunk(
  "gallery/addGalleryItem",
  async (galleryData, thunkAPI) => {
    try {
      return await galleryService.addGalleryItem(galleryData);
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response?.data || "Failed to add gallery item");
    }
  }
);

// Fetch all gallery items
export const fetchGalleryItems = createAsyncThunk(
  "gallery/fetchGalleryItems",
  async (_, thunkAPI) => {
    try {
      return await galleryService.fetchGalleryItems();
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response?.data || "Failed to fetch gallery items");
    }
  }
);

// Update a gallery item
export const updateGalleryItem = createAsyncThunk(
  "gallery/updateGalleryItem",
  async ({ id, galleryData }, thunkAPI) => {
    try {
      return await galleryService.updateGalleryItem(id, galleryData);
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response?.data || "Failed to update gallery item");
    }
  }
);

// Delete a gallery item
export const deleteGalleryItem = createAsyncThunk(
  "gallery/deleteGalleryItem",
  async (id, thunkAPI) => {
    try {
      await galleryService.deleteGalleryItem(id);
      return id;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response?.data || "Failed to delete gallery item");
    }
  }
);

const gallerySlice = createSlice({
  name: "gallery",
  initialState: {
    galleryItems: [],
    isLoading: false,
    isError: false,
    message: "",
  },
  reducers: {
    reset: (state) => {
      state.isLoading = false;
      state.isError = false;
      state.message = "";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchGalleryItems.fulfilled, (state, action) => {
        state.galleryItems = action.payload;
      })
      .addCase(addGalleryItem.fulfilled, (state, action) => {
        state.galleryItems.push(action.payload);
      })
      .addCase(updateGalleryItem.fulfilled, (state, action) => {
        state.galleryItems = state.galleryItems.map((item) =>
          item._id === action.payload._id ? action.payload : item
        );
      })
      .addCase(deleteGalleryItem.fulfilled, (state, action) => {
        state.galleryItems = state.galleryItems.filter((item) => item._id !== action.payload);
      })
      .addMatcher(
        (action) => action.type.endsWith("/pending"),
        (state) => {
          state.isLoading = true;
          state.isError = false;
          state.message = "";
        }
      )
      .addMatcher(
        (action) => action.type.endsWith("/fulfilled"),
        (state) => {
          state.isLoading = false;
        }
      )
      .addMatcher(
        (action) => action.type.endsWith("/rejected"),
        (state, action) => {
          state.isLoading = false;
          state.isError = true;
          state.message = action.payload || "An error occurred.";
        }
      );
  },
});

export const { reset } = gallerySlice.actions;
export default gallerySlice.reducer;
