import { createSlice } from '@reduxjs/toolkit';
import { fetchMenuItems, createMenuItem, updateMenuItem, moveMenuItem, deleteMenuItem } from './menuService';

const initialState = {
  items: [],
  loading: false,
  error: null,
};

const menuSlice = createSlice({
  name: 'menu',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // Fetch Menu Items
    builder.addCase(fetchMenuItems.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(fetchMenuItems.fulfilled, (state, action) => {
      state.loading = false;
      state.items = action.payload;
    });
    builder.addCase(fetchMenuItems.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });

    // Create Menu Item
    builder.addCase(createMenuItem.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(createMenuItem.fulfilled, (state, action) => {
      state.loading = false;
      state.items.push(action.payload);
    });
    builder.addCase(createMenuItem.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });

    // Update Menu Item
    builder.addCase(updateMenuItem.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(updateMenuItem.fulfilled, (state, action) => {
      state.loading = false;
      const index = state.items.findIndex((item) => item.id === action.payload.id);
      if (index !== -1) {
        state.items[index] = action.payload;
      }
    });
    builder.addCase(updateMenuItem.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });

    // Move Menu Item
    builder.addCase(moveMenuItem.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(moveMenuItem.fulfilled, (state, action) => {
      state.loading = false;
      // Update the state to reflect the new parent-child relationships after moving
      state.items = action.payload;
    });
    builder.addCase(moveMenuItem.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });

    // Delete Menu Item
    builder.addCase(deleteMenuItem.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(deleteMenuItem.fulfilled, (state, action) => {
      state.loading = false;
      state.items = state.items.filter((item) => item.id !== action.meta.arg);
    });
    builder.addCase(deleteMenuItem.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });
  },
});

export default menuSlice.reducer;
