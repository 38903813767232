import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { fetchPageById, updatePage } from "../../api/pageApi";
import CustomTableEditor from "../table/CustomTableEditor";

const BASE_URL = process.env.REACT_APP_BACKEND_API_URL;

const PageEdit = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    name: "",
    thumbnail: null,
    thumbnailPreview: null,
    existingImages: [], // For existing images
    newImages: [], // For newly added images
    imagePreviews: [], // Previews for new images
    description: "",
    tableData: [],
    metaTitle: "",
    metaDescription: "",
  });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  // Fetch page details by ID
  useEffect(() => {
    const fetchPageDetails = async () => {
      try {
        const pageData = await fetchPageById(id);

        setFormData({
          name: pageData.name || "",
          thumbnailPreview: pageData.thumbnail
            ? `${BASE_URL}/uploads/${pageData.thumbnail.replace(/\\/g, "/")}`
            : null,
          existingImages: pageData.images || [], // Load existing images from the backend
          newImages: [],
          imagePreviews: [],
          description: pageData.description || "",
          tableData: pageData.tableData || [],
          metaTitle: pageData.metaTitle || "",
          metaDescription: pageData.metaDescription || "",
        });
      } catch (err) {
        console.error("Failed to fetch page details:", err);
        setError("Failed to load page details. Please try again.");
      }
    };

    fetchPageDetails();
  }, [id]);

  // Handle text input changes
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  // Handle thumbnail file input
  const handleThumbnailChange = (e) => {
    const file = e.target.files[0];
    setFormData((prev) => ({
      ...prev,
      thumbnail: file,
      thumbnailPreview: file ? URL.createObjectURL(file) : prev.thumbnailPreview,
    }));
  };

  // Handle new images input
  const handleImagesChange = (e) => {
    const files = Array.from(e.target.files);
    const newPreviews = files.map((file) => URL.createObjectURL(file));
    setFormData((prev) => ({
      ...prev,
      newImages: [...prev.newImages, ...files],
      imagePreviews: [...prev.imagePreviews, ...newPreviews],
    }));
  };

  // Remove specific existing image
  const handleRemoveExistingImage = (index) => {
    setFormData((prev) => ({
      ...prev,
      existingImages: prev.existingImages.filter((_, i) => i !== index),
    }));
  };

  // Remove specific new image
  const handleRemoveNewImage = (index) => {
    setFormData((prev) => {
      const updatedNewImages = [...prev.newImages];
      const updatedPreviews = [...prev.imagePreviews];
      URL.revokeObjectURL(updatedPreviews[index]); // Clean up memory
      updatedNewImages.splice(index, 1);
      updatedPreviews.splice(index, 1);
      return { ...prev, newImages: updatedNewImages, imagePreviews: updatedPreviews };
    });
  };

  // Handle form submission


  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
  
    try {
      const data = new FormData();
      data.append("name", formData.name);
      if (formData.thumbnail) data.append("thumbnail", formData.thumbnail);
  
      // Log existingImages and newImages
      console.log("Existing Images:", formData.existingImages);
      console.log("New Images:", formData.newImages);
  
      data.append("existingImages", JSON.stringify(formData.existingImages));
      formData.newImages.forEach((image) => data.append("images", image));
  
      data.append("description", formData.description);
      data.append("tableData", JSON.stringify(formData.tableData));
      data.append("metaTitle", formData.metaTitle);
      data.append("metaDescription", formData.metaDescription);
  
      // Log the FormData keys and values
      for (let [key, value] of data.entries()) {
        console.log(`${key}:`, value);
      }
  
      await updatePage(id, data);
      alert("Page updated successfully!");
      navigate("/admin/page-list");
    } catch (err) {
      console.error("Error updating page:", err);
      alert("An unexpected error occurred while updating the page.");
    } finally {
      setLoading(false);
    }
  };
  

  if (error) {
    return <div className="text-center text-red-600">{error}</div>;
  }

  return (
    <form onSubmit={handleSubmit} className="space-y-6 p-4 border rounded bg-gray-50">
      <div>
        <label className="block text-sm font-medium">Page Name *</label>
        <input
          type="text"
          name="name"
          value={formData.name}
          onChange={handleInputChange}
          required
          className="block w-full p-2 border rounded"
        />
      </div>

      <div>
        <label className="block text-sm font-medium">Thumbnail *</label>
        <div className="flex items-center space-x-4">
          <label className="cursor-pointer px-4 py-2 bg-blue-600 text-white rounded hover:bg-blue-700">
            Choose File
            <input
              type="file"
              accept="image/*"
              onChange={handleThumbnailChange}
              className="hidden"
            />
          </label>
          {formData.thumbnailPreview && (
      <img
      src={formData.thumbnailPreview || "/default-placeholder.webp"} // Fallback to placeholder if thumbnailPreview is missing
      alt="Thumbnail Preview" // Alt text for accessibility
      className="w-24 h-24 object-cover rounded shadow"
      crossOrigin="anonymous" // Enable cross-origin requests without credentials
      onError={(e) => {
        e.target.onerror = null; // Prevent infinite loop on error
        e.target.src = "/default-placeholder.webp"; // Fallback to placeholder image
      }}
    />
    
          )}
        </div>
      </div>

      <div>
        <label className="block text-sm font-medium">Existing Images</label>
        <div className="flex flex-wrap gap-4 mt-4">
          {formData.existingImages.map((img, index) => (
            <div key={index} className="relative group w-20 h-20">
    <img
  src={img ? `${BASE_URL}/uploads/${img.replace(/\\/g, "/")}` : "/default-placeholder.webp"} // Normalize path and fallback to placeholder
  alt={`Existing Image ${index + 1}`} // Dynamic alt text for accessibility
  className="w-full h-full object-cover rounded shadow"
  crossOrigin="anonymous" // Enable cross-origin requests without credentials
  onError={(e) => {
    e.target.onerror = null; // Prevent infinite loop on error
    e.target.src = "/default-placeholder.webp"; // Fallback to placeholder image
  }}
/>

              <button
                type="button"
                onClick={() => handleRemoveExistingImage(index)}
                className="absolute top-0 right-0 bg-red-500 text-white rounded-full w-5 h-5 flex items-center justify-center hover:bg-red-600"
              >
                &times;
              </button>
            </div>
          ))}
        </div>
      </div>

      <div>
        <label className="block text-sm font-medium">Add New Images</label>
        <input type="file" accept="image/*" multiple onChange={handleImagesChange} />
        <div className="flex flex-wrap gap-4 mt-4">
          {formData.imagePreviews.map((preview, index) => (
            <div key={index} className="relative group w-20 h-20">
              <img
                src={preview}
                alt={`New Image Preview ${index + 1}`}
                className="w-full h-full object-cover rounded shadow"
              />
              <button
                type="button"
                onClick={() => handleRemoveNewImage(index)}
                className="absolute top-0 right-0 bg-red-500 text-white rounded-full w-5 h-5 flex items-center justify-center hover:bg-red-600"
              >
                &times;
              </button>
            </div>
          ))}
        </div>
      </div>

      <div>
        <label className="block text-sm font-medium">Description *</label>
        <CKEditor
          editor={ClassicEditor}
          data={formData.description}
          onChange={(event, editor) => {
            const data = editor.getData();
            setFormData((prev) => ({ ...prev, description: data }));
          }}
        />
      </div>

      <div>
        <label className="block text-sm font-medium">Table</label>
        <CustomTableEditor
          initialRows={0}
          initialColumns={0}
          tableData={formData.tableData}
          onTableChange={(updatedTable) => setFormData((prev) => ({ ...prev, tableData: updatedTable }))}
        />
      </div>

      <div>
        <label className="block text-sm font-medium">Meta Title</label>
        <input
          type="text"
          name="metaTitle"
          value={formData.metaTitle}
          onChange={handleInputChange}
          className="block w-full p-2 border rounded"
        />
      </div>

      <div>
        <label className="block text-sm font-medium">Meta Description</label>
        <textarea
          name="metaDescription"
          value={formData.metaDescription}
          onChange={handleInputChange}
          className="block w-full p-2 border rounded"
        />
      </div>

      <div className="flex space-x-4">
        <button
          type="submit"
          disabled={loading}
          className={`px-4 py-2 bg-blue-600 text-white rounded ${loading ? "opacity-50" : "hover:bg-blue-700"}`}
        >
          {loading ? "Saving..." : "Update"}
        </button>
        <button
          type="button"
          onClick={() => navigate("/admin/page-list")}
          className="px-4 py-2 bg-gray-400 text-white rounded hover:bg-gray-500"
        >
          Cancel
        </button>
      </div>
    </form>
  );
};

export default PageEdit;
