import axios from "../utils/axiosConfig"; // Importing the Axios instance as axiosInstance
import { config } from "../utils/axiosConfig"; // Importing config for headers

// Function to fetch a single page by ID
// src/api/pageApi.js

// Function to fetch all pages
export const fetchAllPages = async () => {
  try {
    const response = await axios.get("/page/all");
    return response.data;
  } catch (error) {
    console.error("Error fetching pages:", error);
    throw error;
  }
};

// Function to fetch a single page by ID

// Function to create a new page
export const createPage = async (data) => {
  try {
    const response = await axios.post("/page", data);
    return response.data;
  } catch (error) {
    console.error("Error creating page:", error);
    throw error;
  }
};

export const fetchPageById = async (id) => {
  if (!id) {
    console.error("ID is undefined in fetchPageById");
    throw new Error("ID is undefined");
  }

  try {
    const response = await axios.get(`/page/id/${id}`);
    return response.data;
  } catch (error) {
    console.error("Error fetching page data:", error);
    throw error;
  }
};

export const updatePage = async (id, data) => {
  try {
    const response = await axios.put(`/page/id/${id}`, data, {
      headers: { "Content-Type": "multipart/form-data" },
    });
    return response.data;
  } catch (err) {
    console.error("Error updating page:", err);
    throw err;
  }
};

// Function to delete a page by ID
export const deletePageById = async (id) => {
  try {
    const response = await axios.delete(`/page/id/${id}`);
    return response.data;
  } catch (error) {
    console.error("Error deleting page:", error);
    throw error;
  }
};

// Fetch a single page by slug
export const getPageBySlug = async (slug) => {
  if (!slug) {
    console.error("Slug is undefined in getPageBySlug");
    throw new Error("Slug is undefined");
  }

  try {
    const response = await axios.get(`/page/${slug}`);
    return response.data;
  } catch (error) {
    console.error("Error fetching page by slug:", error);
    throw error;
  }
};

// export const getPageBySlug = async (slug) => {
//   try {
//     const response = await axios.get(`/page/${slug}`);
//     console.log("API Response for getPageBySlug:", response.data); // Debug log
//     return response.data;
//   } catch (err) {
//     console.error("Error fetching page by slug:", err);
//     throw err;
//   }
// };
