import React, { useEffect, useState } from 'react';
import axios from "../../utils/axiosConfig";
import MenuItem from './MenuItem';
import { DndContext } from '@dnd-kit/core';
import PageMenuManager from '../../pages/Page/PageMenuManager';


const Menu = () => {
  const [menuItems, setMenuItems] = useState([]);
  const [isAdding, setIsAdding] = useState(false);
  const [newMenuItemName, setNewMenuItemName] = useState('');
  const [newMenuItemLink, setNewMenuItemLink] = useState('');

  useEffect(() => {
    const fetchMenu = async () => {
      try {
        const response = await axios.get('/menu');
        setMenuItems(response.data);
      } catch (error) {
        console.error("Error fetching menu:", error);
      }
    };
    fetchMenu();
  }, []);

  const findItemById = (items, id) => {
    for (const item of items) {
      if (item._id === id) return item;
      if (item.children && item.children.length > 0) {
        const found = findItemById(item.children, id);
        if (found) return found;
      }
    }
    return null;
  };

  const removeItemById = (items, id) => {
    for (let i = 0; i < items.length; i++) {
      if (items[i]._id === id) {
        return items.splice(i, 1)[0];
      }
      if (items[i].children && items[i].children.length > 0) {
        const removed = removeItemById(items[i].children, id);
        if (removed) return removed;
      }
    }
    return null;
  };



  const flattenItems = (items) => {
    const flatItems = [];
    const flatten = (list) => {
      list.forEach(item => {
        flatItems.push(item);
        if (item.children) flatten(item.children);
      });
    };
    flatten(items);
    return flatItems;
  };

  const handleDragEnd = (event) => {
    const { active, over } = event;
    if (!over) return;

    const draggedItemId = active.id;
    const overId = over.id;

    const updatedMenuItems = [...menuItems];
    const draggedItem = removeItemById(updatedMenuItems, draggedItemId);

    if (!draggedItem) {
      console.error("Dragged item not found:", draggedItemId);
      return;
    }

    let newParent = null;
    let newLevel = 0;
    let newOrder = 1;

    if (overId.startsWith('drop-parent-')) {
      const targetParentId = overId.replace('drop-parent-', '');
      const targetItem = findItemById(updatedMenuItems, targetParentId);

      if (!targetItem) {
        console.error("Target item not found for sibling drop:", targetParentId);
        return;
      }

      newParent = targetItem.parent || null;
      newLevel = targetItem.level;
      newOrder = targetItem.order;

      updatedMenuItems.forEach((item) => {
        if (item.parent === newParent && item.level === newLevel && item.order >= newOrder) {
          item.order += 1;
        }
      });

      updatedMenuItems.push({ ...draggedItem, parent: newParent, level: newLevel, order: newOrder });

      const siblings = updatedMenuItems.filter(item => item.parent === newParent && item.level === newLevel);
      siblings.sort((a, b) => a.order - b.order);
      siblings.forEach((item, index) => {
        item.order = index + 1;
      });

    } else if (overId.startsWith('drop-child-')) {
      const targetParentId = overId.replace('drop-child-', '');
      const targetParent = findItemById(updatedMenuItems, targetParentId);

      if (!targetParent) {
        console.error("Target parent item not found for child drop:", targetParentId);
        return;
      }

      newParent = targetParent._id;
      newLevel = targetParent.level + 1;
      newOrder = targetParent.children ? targetParent.children.length + 1 : 1;

      if (!targetParent.children) targetParent.children = [];
      targetParent.children.push({ ...draggedItem, parent: newParent, level: newLevel, order: newOrder });
    } else {
      newParent = null;
      newLevel = 0;
      newOrder = 1;

      updatedMenuItems.forEach((item) => {
        if (item.parent === null && item.level === 0 && item.order >= newOrder) {
          item.order += 1;
        }
      });

      updatedMenuItems.push({ ...draggedItem, parent: newParent, level: newLevel, order: newOrder });

      const rootItems = updatedMenuItems.filter(item => item.parent === null);
      rootItems.sort((a, b) => a.order - b.order);
      rootItems.forEach((item, index) => {
        item.order = index + 1;
      });
    }

    setMenuItems(updatedMenuItems); // Update UI immediately
    updateMenuStructure(flattenItems(updatedMenuItems)); // Sync with backend
  };

  const updateMenuStructure = async (updatedMenuItems) => {
    try {
      await axios.put('/menu/update-structure', { menu: updatedMenuItems });
      console.log("Menu structure updated successfully:", updatedMenuItems);
      const response = await axios.get('/menu');
      setMenuItems(response.data);
    } catch (error) {
      console.error("Error updating menu structure:", error.response ? error.response.data : error.message);
    }
  };

  const handleAddMenuItem = async (e) => {
    e.preventDefault();
    try {
      await axios.post('/menu', { name: newMenuItemName, link: newMenuItemLink, parent: null });
      const response = await axios.get('/menu');
      setMenuItems(response.data);
      setIsAdding(false);
      setNewMenuItemName('');
      setNewMenuItemLink('');
    } catch (error) {
      console.error("Error adding menu item:", error);
    }
  };

// Define onSave function
// const handleSave = async (id, updatedData) => {
//   try {
//     const response = await axios.put('/menu/update', { id, ...updatedData });
//     setMenuItems((prevItems) =>
//       prevItems.map((item) => (item._id === id ? response.data : item))
//     );
//     console.log("Menu item updated:", response.data);
//   } catch (error) {
//     console.error("Error updating menu item:", error);
//   }
// };

const handleSave = async (id, updatedData) => {
  try {
    const response = await axios.put('/menu/update', { id, ...updatedData });
    setMenuItems((prevItems) =>
      prevItems.map((item) => (item._id === id ? response.data : item))
    );
    console.log("Menu item updated:", response.data);
  } catch (error) {
    console.error("Error updating menu item:", error);
  }
};

  // Define onDelete function
  const handleDelete = async (id) => {
    if (window.confirm("Are you sure you want to delete this item?")) {
      try {
        await axios.delete(`/menu/delete`, {
          data: { id } // Pass the id within the data object for axios.delete
        });
        setMenuItems((prevItems) => prevItems.filter((item) => item._id !== id));
        console.log("Menu item deleted");
      } catch (error) {
        console.error("Error deleting menu item:", error);
      }
    }
  };
  
  return (
    <DndContext onDragEnd={handleDragEnd}>
    <div className="flex flex-col md:flex-row p-4 gap-4">
      {/* Left side: Add New Menu Item form and Page Menu Manager */}
      <div className="w-full md:w-1/3 bg-white p-4 rounded-lg shadow-md flex flex-col gap-4">
        {/* Add New Menu Item */}
        <div>
          <h2 className="text-lg font-bold text-gray-800 mb-4">Add Custom Menu Item</h2>
          {isAdding && (
            <form onSubmit={handleAddMenuItem} className="space-y-3">
              <input
                type="text"
                placeholder="Menu Item Name"
                value={newMenuItemName}
                onChange={(e) => setNewMenuItemName(e.target.value)}
                className="w-full p-2 border border-gray-300 rounded"
                required
              />
              <input
                type="text"
                placeholder="Menu Item Link"
                value={newMenuItemLink}
                onChange={(e) => setNewMenuItemLink(e.target.value)}
                className="w-full p-2 border border-gray-300 rounded"
                required
              />
              <button
                type="submit"
                className="w-full bg-green-500 text-white py-2 rounded hover:bg-green-600"
              >
                Add Menu Item
              </button>
            </form>
          )}
          <button
            className="bg-blue-500 text-white px-3 py-2 rounded mt-4 w-full hover:bg-blue-600"
            onClick={() => setIsAdding(!isAdding)}
          >
            {isAdding ? 'Cancel' : 'Add New Menu Item'}
          </button>
        </div>

        {/* Page Menu Manager */}
        <div>
          <h2 className="text-lg font-bold text-gray-800 mb-4">Page Menu Manager</h2>
          <PageMenuManager />
        </div>
      </div>

      {/* Right side: Menu Structure */}
      <div className="w-full md:w-2/3 bg-white p-4 rounded-lg shadow-md">
        <h2 className="text-lg font-bold text-gray-800 mb-4">Menu Structure</h2>
        <div>
          {menuItems.map((item) => (
            <MenuItem
              key={item._id}
              item={item}
              onSave={handleSave}
              onDelete={handleDelete}
            />
          ))}
        </div>
      </div>
    </div>
  </DndContext>
  );
};

export default Menu;
