import axios from 'axios';
import { base_url } from '../../utils/base_url';

// Fetch section data
const getSectionOne = async () => {
  const response = await axios.get(`${base_url}/section-one`);
  return response.data;
};

// Update section (including image upload)
const updateSectionOne = async (sectionData) => {
  const response = await axios.put(`${base_url}/section-one`, sectionData, {
    headers: {
      'Content-Type': 'multipart/form-data', // Required for file uploads
    },
  });
  return response.data;
};

export default { getSectionOne, updateSectionOne };
