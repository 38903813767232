// features/auth/authService.js
import axios from "axios";
import { base_url } from "../../utils/base_url";


const login = async (userData) => {
  const response = await axios.post(`${base_url}/auth/login`, userData);
  if (response.data.token) {
    localStorage.setItem("token", response.data.token); // Store token in localStorage
  }
  return response.data;
};

const logout = () => {
  localStorage.removeItem("token"); // Clear token on logout
};

const authService = {
  login,
  logout,
};

export default authService;
