import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { addLogo, updateLogo } from "../../features/client/clientLogoSlice";

const ClientLogoForm = ({ isEditing = false }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();

  const { logos, isLoading } = useSelector((state) => state.clientLogo);

  const [file, setFile] = useState(null);
  const [imagePreview, setImagePreview] = useState("");
  const [existingLogo, setExistingLogo] = useState(null);

  // Use the environment variable for the base API URL
  const API_BASE_URL = process.env.REACT_APP_BACKEND_API_URL ;

  // Fetch existing logo data when editing
  useEffect(() => {
    if (isEditing && id) {
      const logoToEdit = logos.find((logo) => logo._id === id);
      if (logoToEdit) {
        setExistingLogo(logoToEdit);
        setImagePreview(`${API_BASE_URL}/uploads/main/${logoToEdit.img}`);
      }
    }
  }, [isEditing, id, logos, API_BASE_URL]);

  // Handle file input change
  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    setFile(selectedFile);
    setImagePreview(URL.createObjectURL(selectedFile));
  };

  // Handle form submission for both add and edit
  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const formData = new FormData();
      if (file) {
        formData.append("img", file); // Attach image file
      }

      if (isEditing) {
        // Update existing logo
        await dispatch(updateLogo({ id, logoData: formData })).unwrap();
        alert("Logo updated successfully!");
      } else {
        // Add new logo
        await dispatch(addLogo(formData)).unwrap();
        alert("Logo added successfully!");
      }

      navigate("/admin/client-logos"); // Redirect to logo list
    } catch (error) {
      console.error("Error submitting form:", error);
      alert("An error occurred. Please try again.");
    }
  };

  return (
    <form
      onSubmit={handleSubmit}
      className="max-w-md mx-auto p-6 bg-white shadow-md rounded-lg space-y-6"
    >
      <h2 className="text-2xl font-semibold text-gray-700 text-center">
        {isEditing ? "Edit Client Logo" : "Add New Client Logo"}
      </h2>

      {/* File Input with Aspect Ratio Note */}
      <div>
        <label className="block text-gray-700 font-medium mb-2">
          Upload Logo <span className="text-sm text-gray-500">(3:1 Ratio, e.g., 404x136 pixels)</span>
        </label>
        <input
          type="file"
          onChange={handleFileChange}
          className="block w-full p-2 border rounded"
        />
      </div>

      {/* Image Preview */}
      {imagePreview && (
        <div className="text-center">
<img
  src={imagePreview || "/default-placeholder-logo.webp"} // Fallback to a placeholder image
  alt="Logo Preview" // Alt text for accessibility
  className="w-32 h-32 object-contain mx-auto border rounded"
  crossorigin="anonymous" // Enable cross-origin requests without credentials
  onError={(e) => {
    e.target.onerror = null; // Prevent infinite loop on error
    e.target.src = "/default-placeholder-logo.webp"; // Fallback to placeholder image
  }}
/>

        </div>
      )}

      {/* Submit Button */}
      <button
        type="submit"
        disabled={isLoading}
        className="w-full py-2 text-white font-semibold rounded bg-blue-500 hover:bg-blue-600 transition"
      >
        {isLoading
          ? isEditing
            ? "Updating..."
            : "Uploading..."
          : isEditing
          ? "Update Logo"
          : "Add Logo"}
      </button>
    </form>
  );
};

export default ClientLogoForm;
