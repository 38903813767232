import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchSectionOne } from "../../features/sectionOne/sectionOneSlice";
import { useNavigate } from "react-router-dom";
import { FaEdit } from "react-icons/fa";

// Use REACT_APP_BACKEND_API_URL from environment variables
const BASE_URL = process.env.REACT_APP_BACKEND_API_URL;

const SectionOneDisplay = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { data: section, isLoading, isError, message } = useSelector(
    (state) => state.sectionOne
  );

  // Fetch section data when the component mounts
  useEffect(() => {
    dispatch(fetchSectionOne());
  }, [dispatch]);

  // Navigate to the editor form when the edit button is clicked
  const handleEditClick = () => {
    navigate("/admin/section-one-form");
  };

  return (
    <div className="p-10 bg-blue-50 flex justify-center">
      <div className="flex max-w-4xl w-full bg-white shadow-md rounded-lg overflow-hidden">
        {isLoading ? (
          <p className="text-center text-gray-500 w-full">Loading...</p>
        ) : isError ? (
          <p className="text-center text-red-500 w-full">
            {message || "Error loading section data"}
          </p>
        ) : section ? (
          <>
            {/* Left: Image */}
            <div className="w-1/2">
            <img
  src={
    section.img
      ? `${BASE_URL}/uploads/main/${section.img.replace(/\\/g, "/")}` // Normalize path
      : "/default-placeholder.webp" // Fallback to a placeholder image
  }
  alt={section.title || "Section Image"} // Dynamic alt text for accessibility
  className="w-full h-full object-cover rounded-l-lg"
  crossOrigin="anonymous" // Enable cross-origin requests without credentials
  onError={(e) => {
    e.target.onerror = null; // Prevent infinite loop on error
    e.target.src = "/default-placeholder.webp"; // Fallback to placeholder image
  }}
/>

            </div>
            {/* Right: Content */}
            <div className="w-1/2 p-6 flex flex-col justify-center">
              <h3 className="text-gray-500 font-medium text-sm mb-2">
                {section.subtitle}
              </h3>
              <h1 className="text-3xl font-bold text-gray-800 mb-4">
                {section.title}
              </h1>
              <p className="text-gray-600 text-base leading-relaxed mb-6">
                {section.description}
              </p>
              <button
                onClick={handleEditClick}
                className="text-gray-500 flex items-center space-x-1 text-sm hover:text-blue-600 transition"
              >
                <FaEdit className="text-gray-500" />
                <span>Edit Section</span>
              </button>
            </div>
          </>
        ) : (
          <p className="text-center text-gray-500 w-full">
            No section data available.
          </p>
        )}
      </div>
    </div>
  );
};

export default SectionOneDisplay;
