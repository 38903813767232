import React from 'react'

import SectionOneDisplay from './SectionOneDisplay'

const SectionOneForm = () => {
  return (
    <div>
      <SectionOneDisplay/>
    </div>
  )
}

export default SectionOneForm
