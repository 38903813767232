// In a separate file named axiosConfig.js
import axios from "axios";
// export const config = {
//     headers: {
//       Authorization: `Bearer ${localStorage.getItem('token') || ''}`,
//       Accept: 'application/json',
//     },
//   };
  
// In a separate file named axiosConfig.js

// In a separate file named axiosConfig.js

// const instance = axios.create({
//   baseURL: "http://localhost:3004/api", // Replace with your actual base URL
// });


// export const config = () => {
//     const token = localStorage.getItem('token');
//     return {
//       headers: {
//         Authorization: token ? `Bearer ${token}` : '',
//         Accept: 'application/json',
//       },
//     };
//   };
  



//   export default instance; // Export as default
  
  



// Use the environment variable for the API base URL
axios.defaults.baseURL = process.env.REACT_APP_API_URL;




export const config = () => {
  const token = localStorage.getItem('token');
  return {
    headers: {
      Authorization: token ? `Bearer ${token}` : '',
      Accept: 'application/json',
    },
  };
};

export default axios;
