//Gallery.js
import React from 'react';
import GalleryList from './GalleryList';

const Gallery = () => {
  return (
    <div>
      <GalleryList />
    </div>
  );
};

export default Gallery;
