// src/features/clientLogo/clientLogoSlice.js
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import clientLogoService from "./clientLogoService";



// Create a new logo
export const addLogo = createAsyncThunk(
  "clientLogo/addLogo",
  async (logoData, thunkAPI) => {
    try {
      return await clientLogoService.addLogo(logoData);
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response?.data || "Failed to add logo");
    }
  }
);

// Fetch all logos
export const fetchLogos = createAsyncThunk(
  "clientLogo/fetchLogos",
  async (_, thunkAPI) => {
    try {
      return await clientLogoService.fetchLogos();
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response?.data || "Failed to fetch logos");
    }
  }
);

// Update a logo
export const updateLogo = createAsyncThunk(
  "clientLogo/updateLogo",
  async ({ id, logoData }, thunkAPI) => {
    try {
      return await clientLogoService.updateLogo(id, logoData);
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response?.data || "Failed to update logo");
    }
  }
);

// Delete a logo
export const deleteLogo = createAsyncThunk(
  "clientLogo/deleteLogo",
  async (id, thunkAPI) => {
    try {
      await clientLogoService.deleteLogo(id);
      return id;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response?.data || "Failed to delete logo");
    }
  }
);

const clientLogoSlice = createSlice({
  name: "clientLogo",
  initialState: {
    logos: [],
    uploadPath: "",
    isLoading: false,
    isError: false,
    message: "",
  },
  reducers: {
    reset: (state) => {
      state.isLoading = false;
      state.isError = false;
      state.message = "";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchLogos.fulfilled, (state, action) => {
        state.logos = action.payload;
      })
      
      .addCase(addLogo.fulfilled, (state, action) => {
        state.logos.push(action.payload);
      })
      .addCase(updateLogo.fulfilled, (state, action) => {
        state.logos = state.logos.map((logo) =>
          logo._id === action.payload._id ? action.payload : logo
        );
      })
      .addCase(deleteLogo.fulfilled, (state, action) => {
        state.logos = state.logos.filter((logo) => logo._id !== action.payload);
      })
      .addMatcher(
        (action) => action.type.endsWith("/pending"),
        (state) => {
          state.isLoading = true;
          state.isError = false;
          state.message = "";
        }
      )
      .addMatcher(
        (action) => action.type.endsWith("/fulfilled"),
        (state) => {
          state.isLoading = false;
        }
      )
      .addMatcher(
        (action) => action.type.endsWith("/rejected"),
        (state) => {
          state.isLoading = false;
          state.isError = true;
        }
      );
      
  },
});

export const { reset } = clientLogoSlice.actions;
export default clientLogoSlice.reducer;
