const TableDisplay = ({ tableData }) => {
  if (!tableData || tableData.length === 0) {
    return <p>No table data available.</p>;
  }

  return (
    <div className="table-data">
      <table className="custom-table w-full border-collapse border border-gray-300">
        <tbody>
          {tableData.map((row, rowIndex) => (
            <tr key={rowIndex}>
              {row.map((cell, cellIndex) => {
                // Skip rendering hidden cells (merged parts)
                if (cell.hidden) return null;

                // Extract styles
                const {
                  textColor,
                  fontSize,
                  isBold,
                  isItalic,
                  textAlignment,
                  borderThickness,
                  borderStyle,
                  borderColor,
                  backgroundColor,
                } = cell.styles || {};

                return (
                  <td
                    key={cellIndex}
                    rowSpan={cell.rowSpan || 1}
                    colSpan={cell.colSpan || 1}
                    style={{
                      width: `${cell.width || 100}px`,
                      height: `${cell.height || 30}px`,
                      color: textColor || "#000",
                      fontSize: `${fontSize || 14}px`,
                      textAlign: textAlignment || "left",
                      fontWeight: isBold ? "bold" : "normal",
                      fontStyle: isItalic ? "italic" : "normal",
                      backgroundColor: backgroundColor || "transparent",
                      borderWidth: `${borderThickness || 1}px`,
                      borderStyle: borderStyle || "solid",
                      borderColor: borderColor || "#ccc",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      wordWrap: "break-word",
                    }}
                  >
                    {cell.value}
                  </td>
                );
              })}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default TableDisplay;
