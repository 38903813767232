// src/pages/BlogDetails.js
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useParams } from 'react-router-dom';
import { getBlogBySlug } from '../../api/blogApi';

const BlogDetails = () => {
  const { slug } = useParams();
  const [blog, setBlog] = useState(null);

  useEffect(() => {
    const fetchBlog = async () => {
      try {
        const response = await getBlogBySlug(slug);
        setBlog(response.data);
      } catch (error) {
        console.error('Error fetching blog:', error);
      }
    };

    fetchBlog();
  }, [slug]);

  if (!blog) return <p>Loading...</p>;

  return (
    <div>
      <Helmet>
        <title>{blog.title}</title>
        <meta name="description" content={blog.metaDescription} />
        <meta name="keywords" content={blog.keywords.join(', ')} />
      </Helmet>
      <h1>{blog.title}</h1>
      <div>
        {blog.content.map((node, index) => (
          <RenderNode node={node} key={index} />
        ))}
      </div>
    </div>
  );
};

const RenderNode = ({ node }) => {
  switch (node.type) {
    case 'paragraph':
      return <p>{node.children.map((child, index) => <RenderLeaf leaf={child} key={index} />)}</p>;
    case 'heading-one':
      return <h1>{node.children.map((child, index) => <RenderLeaf leaf={child} key={index} />)}</h1>;
    case 'heading-two':
      return <h2>{node.children.map((child, index) => <RenderLeaf leaf={child} key={index} />)}</h2>;
    case 'image':
      return <img src={node.url} alt={node.caption || 'Blog image'} />;
    default:
      return null;
  }
};

const RenderLeaf = ({ leaf }) => {
  let content = leaf.text;
  if (leaf.bold) content = <strong>{content}</strong>;
  if (leaf.italic) content = <em>{content}</em>;
  if (leaf.underline) content = <u>{content}</u>;
  return <span>{content}</span>;
};

export default BlogDetails;
