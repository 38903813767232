// src/components/AddMenuButton.js
import React from 'react';
import { addPagesToMenu } from '../../api/api';

const AddMenuButton = ({ selectedPageIds }) => {
  const handleAddMenuItems = async () => {
    if (selectedPageIds.length === 0) {
      alert("No pages selected");
      return;
    }

    try {
      await addPagesToMenu(selectedPageIds);
      alert("Menu items added successfully!");
    } catch (error) {
      console.error("Error adding menu items:", error);
      alert("Error adding menu items.");
    }
  };

  return (
    <button
      onClick={handleAddMenuItems}
      className="mt-4 px-4 py-2 bg-blue-600 text-white font-semibold rounded-lg shadow hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500"
    >
      Add Selected Pages as Menu Items
    </button>
  );
};

export default AddMenuButton;
