import React from 'react'









const AdminDashboard = () => {
  return (
    <div>
      admin dashboard



      {/* <MenuEditor/> */}
      {/* <HoverTrackButton/> */}

    </div>
  )
}

export default AdminDashboard
