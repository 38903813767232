import React from 'react'
import ClientLogoList from './ClientLogoList'

const ClientLogo = () => {
  return (
    <div>
      <ClientLogoList/>
    </div>
  )
}

export default ClientLogo
