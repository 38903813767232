import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { FaEdit, FaTrashAlt } from "react-icons/fa";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import { fetchSliderItems, deleteSliderItem } from "../../features/slider/sliderSlice";

// Use REACT_APP_BACKEND_API_URL from environment variables
const BASE_URL = process.env.REACT_APP_BACKEND_API_URL;

const SliderList = () => {
  const dispatch = useDispatch();
  const { sliderItems, isLoading, isError, message } = useSelector(
    (state) => state.slider
  );

  useEffect(() => {
    dispatch(fetchSliderItems());
  }, [dispatch]);

  const handleDelete = (id) => {
    if (window.confirm("Are you sure you want to delete this slider?")) {
      dispatch(deleteSliderItem(id));
    }
  };

  return (
    <div className="p-6 max-w-6xl mx-auto bg-white shadow-md rounded-lg">
      {/* Header Section */}
      <div className="flex justify-between items-center mb-6">
        <h2 className="text-2xl font-semibold text-gray-800">Sliders</h2>
        <Link
          to="/admin/sliders/add"
          className="bg-blue-600 text-white px-4 py-2 rounded hover:bg-blue-700 transition"
        >
          Add New Slider
        </Link>
      </div>

      {/* Loading State */}
      {isLoading && (
        <div className="flex justify-center items-center">
          <AiOutlineLoading3Quarters className="animate-spin text-3xl text-gray-500" />
        </div>
      )}

      {/* Error State */}
      {isError && (
        <div className="text-center text-red-500">
          <p>{message || "Failed to fetch slider items."}</p>
        </div>
      )}

      {/* Sliders List */}
      {!isLoading && sliderItems.length > 0 && (
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6">
          {sliderItems.map((slider, index) => (
            <div
              key={slider._id || index} // Fallback key to index if _id is missing
              className="flex flex-col bg-gray-100 rounded-lg shadow hover:shadow-lg transition"
            >
              {/* Slider Image */}
              <div className="w-full h-36 bg-gray-200 flex items-center justify-center overflow-hidden rounded-t-lg">
              <img
  src={
    slider.img
      ? `${BASE_URL}/uploads/main/${slider.img.replace(/\\/g, "/")}` // Normalize path
      : "/default-placeholder.webp" // Fallback to placeholder image
  }
  alt={slider.title || "Slider Image"} // Dynamic alt text for accessibility
  className="w-full h-full object-cover"
  crossOrigin="anonymous" // Enable cross-origin requests without credentials
  onError={(e) => {
    e.target.onerror = null; // Prevent infinite loop on error
    e.target.src = "/default-placeholder.webp"; // Fallback to placeholder image
  }}
/>

              </div>

              {/* Slider Details */}
              <div className="flex flex-col px-4 py-2 bg-gray-50 w-full">
                <h3
                  className="text-lg font-semibold truncate"
                  title={slider.title || "No Title"}
                >
                  {slider.title || "No Title"}
                </h3>
                <p
                  className="text-sm text-gray-600 truncate"
                  title={slider.description || "No Description"}
                >
                  {slider.description || "No Description"}
                </p>
              </div>

              {/* Actions */}
          {/* Actions */}
<div className="flex justify-end w-full px-4 py-2 bg-gray-50 space-x-2">
  <Link
    to={`/admin/sliders/edit/${slider._id}`}
    className="text-gray-500 hover:text-blue-600 transition"
    title="Edit"
  >
    <FaEdit className="w-4 h-4" />
  </Link>
  <button
    onClick={() => handleDelete(slider._id)}
    className="text-gray-500 hover:text-red-600 transition"
    title="Delete"
  >
    <FaTrashAlt className="w-4 h-4" />
  </button>
</div>

            </div>
          ))}
        </div>
      )}

      {/* No Sliders Found */}
      {!isLoading && sliderItems.length === 0 && !isError && (
        <p className="text-center text-gray-500">
          No sliders found. Add a new slider to get started!
        </p>
      )}
    </div>
  );
};

export default SliderList;
